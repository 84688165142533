<template>
  <div class="file-upload">
    <div class="loading" v-if="inProgress">
      <button class="btn btn-secondary spinner" type="button" disabled>
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
    <div class="preview-container" v-if="files.length">
      <div v-for="file in files" :key="file.name" class="preview-card">
        <div>
          <img class="preview-img" :src="generateThumbnail(file)" />
          <p :title="file.name">
            {{ makeName(file.name) }}
          </p>
        </div>
        <div>
          <button
            type="button"
            @click="remove(files.indexOf(file))"
            title="Remove file"
          >
            <b>&times;</b>
          </button>
        </div>
      </div>
    </div>
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      v-if="complete === false && inProgress === false"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label for="fileInput" class="file-label">
        <div>Drop files here.</div>
      </label>
      <!-- <button
        @click="uploadFiles"
        class="btn btn-primary"
        v-if="files.length > 0 && !isDragging"
      >
        Upload
      </button> -->

      <!-- <div class="form-button-container">
        <button
          @click="uploadFiles"
          class="btn btn-primary"
          v-if="files.length > 0 && !isDragging"
        >
          Upload
        </button>
      </div> -->
    </div>

    
    <div v-if="complete">
      <div>Upload complete. Please publish from Clay</div>
    </div>
  </div>
</template>

<script>
import { api } from "@/api/api.js";
import exifr from "exifr";
import { EventBus } from "@/components/EventBus.js";

export default {
  data() {
    return {
      isDragging: false,
      files: [],
      complete: false,
      inProgress: false,
    };
  },
  props: {
    cId: {
      type: String,
      default: null,
    },
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
    },

    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },

    makeName(name) {
      return name.split(".")[0];
    },

    remove(i) {
      this.files.splice(i, 1);
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
      EventBus.$emit("showUploadBtn")
    },
    uploadFiles() {
      const unsorted = this.files;
      let parsed = [];
      this.$store.state.ignoreDataSource = true;
      const clayPop = this.$store.state.activePwClayPopulation;
      let nextX = 0;
      if (clayPop.length) {
        nextX = Math.max(...clayPop) + 3;
      }
      this.inProgress = true;
      const syncUpload = (files, i) => {
        const pfile = files[i];
        if (!pfile) {
          this.$store.state.ignoreDataSource = false;
          clayPop.push(nextX);
          this.complete = true;
          this.inProgress = false;
          this.files.length = 0;
          EventBus.$emit("uploadComplete")
        } else {
          const file = pfile.file;
          api.upload(file, i).then((res) => {
            const pos = { q: nextX, r: i };
            const image = res.data.uri;
            api
              .saveNewPlaceEntity(
                this.makeName(file.name),
                pos,
                pfile.geo,
                image,
                pfile.time,
                this.activePwCId,
                this.$store.state.activeProject.value
              )
              .then(() => syncUpload(files, ++i));
          });
        }
      };
      const syncParse = (files, i) => {
        const file = files[i];
        if (!file) {
          const sorted = parsed.sort((a, b) => {
            const r = a.timeMs < b.timeMs ? -1 : 1;
            return r;
          });
          syncUpload(sorted, 0);
        } else {
          exifr.parse(file).then((output) => {
            const pfile = {
              file: file,
              geo: {
                longitude: output.longitude,
                latitude: output.latitude,
              },
              time: output.CreateDate.toISOString(),
              timeMs: output.CreateDate.getTime(),
            };
            parsed.push(pfile);
            syncParse(files, ++i);
          });
        }
      };
      syncParse(unsorted, 0);
    },
  },
  computed: {
    activePwCId() {
      return this.$store.state.activePwCId;
    },
  },
  created () {
    EventBus.$on("uploadFiles", () => {
      this.uploadFiles();
    })
  }
};
</script>

<style>
.file-upload {
  height: inherit;
  overflow: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: start;
  text-align: center;
  flex-direction: column;
}
.dropzone-container {
  width: 100%;
  height: 30vh;
  position: relative;
  color: #666;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.preview-container + .dropzone-container {
  margin-top: -20vh;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 20vh;
  font-size: var(--fsize-footer);
}
.preview-container p {
  word-wrap: break-word;
}
.preview-card {
  display: flex;
  margin-left: 5px;
  width: 20%;
  position: relative;
}
.preview-card div:nth-child(1) {
  width: 100%;
}
.preview-card button {
  border: none;
}
.preview-card div:nth-child(2) {
  position: absolute;
  right: 0;
}
.preview-img {
  width: 100%;
  height: auto;
  max-height: 6vh;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.393);
  z-index: 100;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -25px;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
</style>