<template>
  <div class="timeline-container">
    <chevron-left :size="30" @click="scrollLeft" />
    <vue-horizontal-timeline :items="items" />
    <chevron-right :size="30" @click="scrollRight" />
    <DropdownDatepicker
      :default-date="datePickerValue"
      default-date-format="mmm dd yyyy"
      v-bind:on-day-change="onDateChange"
      v-bind:on-month-change="onMonthChange"
      v-bind:on-year-change="onYearChange"
    />
    <b-toast id="daterange" title="Date out of range" :solid="true">
      Please pick a date within {{ startDate.toDateString() }} and
      {{ endDate.toDateString() }}
    </b-toast>
  </div>
</template>
 
<script>
import VueHorizontalTimeline from "vue-horizontal-timeline";
import { EventBus } from "@/components/EventBus.js";
import { api } from "@/api/api.js";
import DropdownDatepicker from "vue-dropdown-datepicker";

export default {
  name: "events-timeline",
  props: {
    startDate: {
      type: Date,
      default: () => new Date("August 25, 2023"),
    },
    endDate: {
      type: Date,
      default: () => new Date(Date.now()),
    },
    stepDays: {
      type: Number,
      default: 1,
    },
    cId: {
      type: String,
      default: null,
    },
  },
  components: {
    VueHorizontalTimeline,
    DropdownDatepicker,
  },
  mounted: function () {
    EventBus.$on("dataInitialized", (cId, entities) => {
      if (this.cId === cId) {
        this.entities = entities;
        this.readEntityHistory();
      }
    });
    const equalsEndDate = (d) => {
      const r =
        d.getDate() === this.endDate.getDate() &&
        d.getMonth() === this.endDate.getMonth() &&
        d.getYear() === this.endDate.getYear();
      return r;
    };
    const pushDate = (d) => {
      this.items.push({
        content:
          days[d.getDay()] +
          " - " +
          d.getDate().toString().padStart(2, "0") +
          "/" +
          (d.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          d.getFullYear().toString().substring(2, 4),
        stepCssClass: "date",
        boxCssClass: "d" + d.getDate() + "-" + (d.getMonth() + 1),
      });
    };
    const days = ["S", "M", "Tu", "W", "Th", "F", "S"];
    let d = new Date(this.startDate);
    for (; !equalsEndDate(d); d.setDate(d.getDate() + 1)) {
      pushDate(d);
    }
    pushDate(d);
  },
  data() {
    return {
      items: [],
      scrollAmount: 0,
      scrollable: null,
      maxScrollAmt: 0,
      datePickerValue:
        this.endDate.getFullYear() +
        "-" +
        (this.endDate.getMonth() + 1) +
        "-" +
        this.endDate.getDate(),
    };
  },
  methods: {
    scrollLeft: function () {
      if (this.cId !== this.$store.getters.activeContext) {
        return;
      }
      if (this.scrollable.scrollLeft >= 0) {
        this.scrollAmount = Math.max(0, this.scrollAmount - 200);
        this.scrollable.scrollTo({
          left: this.scrollAmount,
          behavior: "smooth",
        });
      }
    },

    getPos(cls) {
      const dmarker = document.querySelector(
        ".t-" + this.cId + " .time " + cls
      );
      const dot = document.querySelector("#" + cls);
      return (dot.clientLeft = dmarker.clientLeft);
    },

    scrollRight: function () {
      if (this.cId !== this.$store.getters.activeContext) {
        return;
      }
      if (this.scrollable.scrollLeft <= this.maxScrollAmt) {
        this.scrollAmount = Math.min(
          this.maxScrollAmt,
          this.scrollAmount + 200
        );
        this.scrollable.scrollTo({
          left: this.scrollAmount,
          behavior: "smooth",
        });
      }
    },

    readEntityHistory: function () {
      if (this.cId !== this.$store.getters.activeContext) return;
      const entities = this.entities;
      const _this = this;

      if (!entities) return;
      entities.forEach((entity) => {
        const valueIds = entity.properties.map((prop) => prop.value.id);
        valueIds.map((id) => {
          api.getValueHistory(id).then((history) => {
            history.data._embedded["be:value_history"].forEach((ev) => {
              const ts = new Date(ev.updated.timestamp);
              const cls = ts.getDate() + "-" + (ts.getMonth() + 1);
              const timeDiv = document.querySelector(
                ".t-" + _this.cId + " .d" + cls
              );
              if (!timeDiv) {
                return;
              }
              let thisDay = timeDiv.parentElement.querySelector(" .thisDay");
              if (!thisDay) {
                thisDay = document.createElement("div");
                thisDay.className = "thisDay";
                timeDiv.parentElement.appendChild(thisDay);
              }
              const el = document.createElement("div");
              el.className = "dot " + ev.eventName.toLowerCase();
              el.onclick = function () {
                EventBus.$emit("timelineDotClick", {
                  entity: entity,
                  type: "knowledge",
                });
              };
              const value =
                "\n Entity: " + // eslint-disable-line
                entity.properties.filter(
                  (p) => p.attributeRef.handle === "name"
                )[0].value.data.name;

              el.setAttribute(
                "data-title",
                ev.eventName +
                  " by " +
                  ev.updated.user.emailAddress.substring(
                    0,
                    ev.updated.user.emailAddress.indexOf("@")
                  ) +
                  " at " +
                  ts.toTimeString().substring(0, 9) +
                  ":" +
                  (ev.data.name ? "\n'" + ev.data.name + "'" : "") +
                  (ev.data.value ? ": '" + ev.data.value + "' " : "") +
                  value
              );
              thisDay.appendChild(el);
            });
          });
        });
      });
      _this.scrollable = document.querySelector(
        ".t-" + _this.cId + " .timeline"
      );
      const toEnd = document.querySelector(".t-" + _this.cId + " .timeline ol");
      if (toEnd) {
        _this.scrollable.scrollTo({
          left: toEnd.clientWidth,
          behavior: "instant",
        });
        _this.scrollAmount = _this.scrollable.scrollLeft;
        _this.maxScrollAmt = _this.scrollable.scrollLeft;
      }
    },
    setPickerValue(d) {
      if (this.cId !== this.$store.getters.activeContext) {
        return;
      }
      this.datePickerValue =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    },
    scrollToPickerValue() {
      if (this.cId !== this.$store.getters.activeContext) {
        return;
      }
      const d = new Date(this.datePickerValue);
      if (d < this.startDate || d > this.endDate) {
        this.$bvToast.show("daterange");
      }
      const text =
        d.getDate().toString().padStart(2, "0") +
        "/" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        d.getFullYear().toString().substring(2, 4);
      document
        .querySelectorAll(".t-" + this.cId + " span.content")
        .forEach((e) => {
          if (e.innerHTML.indexOf(text) > -1) {
            e.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
            e.classList.add("blink");
            setTimeout(function () {
              e.classList.remove("blink");
            }, 10000);
            return;
          }
        });
    },
    onDateChange(date) {
      const d = new Date(this.datePickerValue);
      d.setDate(date);
      this.setPickerValue(d);
      this.scrollToPickerValue();
    },
    onMonthChange(month) {
      const d = new Date(this.datePickerValue);
      d.setMonth(month - 1);
      this.setPickerValue(d);
      this.scrollToPickerValue();
    },
    onYearChange(year) {
      const d = new Date(this.datePickerValue);
      d.setYear(year);
      this.setPickerValue(d);
      this.scrollToPickerValue();
    },
  },
};
</script>
 
<style>
.temporal {
  width: 100%;
  position: absolute;
  z-index: 9;
  background: #333;
  height: var(--temporalbar-ht);
  overflow-y: visible;
}
.temporal.push-left {
  width: calc(100% - 50vw);
}
.temporal .material-design-icon {
  vertical-align: top;
  line-height: var(--temporalbar-ht);
  padding: 1vh;
}
.temporal .vue-horizontal-timeline {
  height: var(--temporalbar-ht);
  background-color: transparent;
  width: 85%;
  display: inline-block;
  overflow-x: hidden;
  box-shadow: none;
  overflow-y: visible;
}
.temporal .vue-horizontal-timeline .timeline {
  padding: 0;
  background-color: #333;
  overflow-y: visible;
}
.temporal .vue-horizontal-timeline .timeline::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.temporal .vue-horizontal-timeline .timeline .protocol {
  font-size: var(--fsize-footer);
}
.temporal .vue-horizontal-timeline .timeline .title {
  font-size: var(--fsize-footer);
}
.temporal .vue-horizontal-timeline .timeline ol {
  width: min-content;
  padding: 4vh 0 3vh;
  margin-bottom: 0px;
  line-height: 2vh;
  height: inherit;
}
.temporal .vue-horizontal-timeline .timeline ol {
  padding: 7vh 0 0 0;
  margin-bottom: 0px;
  overflow-y: clip;
  overflow-x: hidden;
  line-height: 3vh;
  /* height: 3vh; */
}
.temporal .vue-horizontal-timeline .timeline ol li {
  height: 2px;
  width: 100px;
  left: 0.5rem !important;
  background-color: #fff !important;
  margin-bottom: calc(2vh - 8px) !important;
}
.temporal .vue-horizontal-timeline .timeline ol li::after {
  left: 0px !important;
}
.temporal .vue-horizontal-timeline .timeline ol li:last-child {
  display: none;
}
.vue-horizontal-timeline .timeline ol li .time {
  min-width: auto !important;
  left: 0 !important;
  text-align: center;
  width: 100%;
  z-index: 10;
}
.date {
  background-color: #fff !important;
}
.temporal .vue-horizontal-timeline .timeline ol li.add-step.date::after {
  background-color: #fff !important;
  width: 2px;
  border-radius: 0px;
}
.vue-horizontal-timeline .timeline ol li.add-step:hover .time {
  display: block;
  z-index: 999;
  top: 0 !important;
}
.temporal .vue-horizontal-timeline .timeline ol li:nth-child(odd) .time {
  top: 0;
  transform: none;
  -webkit-transform: none;
}

.temporal .vue-horizontal-timeline .timeline ol .time::before {
  top: -8px;
  border: 0 !important;
  -webkit-filter: none !important;
  filter: none !important;
}
.temporal .vue-horizontal-timeline .timeline ol li .time {
  top: 0 !important;
  border: 0px !important;
  background: transparent !important;
  color: #fff !important;
  box-shadow: none !important;
  padding: 0;
}

.temporal .vue-horizontal-timeline .timeline ol li .time .content {
  font-size: var(--fsize-footer);
}
.temporal
  .vue-horizontal-timeline
  .timeline
  ol
  li:nth-child(odd)
  .time:hover::before {
  animation: none;
  -webkit-animation: none;
}
.dot {
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 100%;
  position: inherit;
  display: list-item;
  float: left;
  margin-left: 0.1vw;
  margin-bottom: 0.1vw;
  bottom: 7vh;
  background: white;
  z-index: 12;
}

.dot.valueupdated {
  background: #4e4e4e;
}

.dot.valueassignedtoproject {
  background: #212121;
}

.dot.valuecreated {
  background: #adadad;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
.thisDay {
  margin-top: 0.1vw;
}

.thisDay:hover .dot {
  opacity: 0.1;
}

.thisDay:hover .dot:hover {
  opacity: 1 !important;
}

[data-title]:after {
  content: attr(data-title);
  white-space: pre-line;
  background-color: #fff;
  color: #111;
  font-size: var(--fsize-footer);
  position: absolute;
  padding: 3px;
  top: 0;
  left: 50%;
  opacity: 0;
  border: 1px solid #ccc;
  z-index: 13;
  visibility: hidden;
  border-radius: 2px;
  min-width: 150px;
  line-height: 0.6rem;
}
[data-title] {
  position: relative;
}

.date-dropdowns {
  max-width: 10%;
  height: 10vh;
  float: right;
  margin-right: 1vw;
}

.date-dropdowns select {
  display: list-item;
  font-size: var(--fsize-footer);
  background: transparent;
  border: 0px;
  color: #fff;
  height: calc(var(--temporalbar-ht) / 3);
  width: 100%;
}

.date-dropdowns select:focus-visible,
.date-dropdowns select:focus,
.date-dropdowns select:active {
  border: 0px;
  outline: 0px;
}

.split-view .vue-horizontal-timeline {
  width: 72%;
}

select * {
  color: black;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  border: solid 1px white;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>