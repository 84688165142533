import axios from "axios";
import config from './config.json';
import splitFile from "./splitFile";

class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = config.baseUrl;
    this.organization = config.organization;
    this.subdomain = "mobility";
    this.init();
  }

  init = (tok) => {

    let headers = {
      Accept: "application/json",
      tenant: "clay"
    };
    if (tok) {
      headers["Authentication-Token"] = tok;
    }

    this.client = axios.create({
      withCredentials: true,
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };

  initJsonBin = () => {
    this.jsonClient = axios.create({
      baseURL: "https://api.jsonstorage.net/v1/json/06f1f37e-0b83-4249-a2ac-ee86ec9e66a5/bbac9c5c-8bcf-4adf-beb9-3c031749afe0",
    });
    return this.jsonClient;
  }

  signin = (obj) => {
    return this.client.post("/authentications", obj).then(res => {
      const authToken = res.headers["authentication-token"]
      this.client.defaults.headers["Authentication-Token"] = authToken;
      localStorage.setItem("tm-user-token", authToken);
      return res;
    });
  }

  getMe = (token) => {
    if (token) {
      // called from Login to check if valid token exists.
      this.init(token);
    }
    return this.client.get("/users/me");
  }

  getCurrentAuths = () => {
    return this.client.get("/authentications/current");
  }

  getCurrentTenants = () => {
    return this.client.get("/tenants/current");
  }

  deleteCurrent = () => {
    return this.client.delete("/authentications/current").then(res => {
      localStorage.removeItem("tm-user-token");
      this.init(null);
      return res;
    });
  }

  resetPassword = (obj) => {
    return this.client.post("/users/password_reset_request" + "?sd=" + this.subdomain, obj)
  }
  getExhibit = (exhibitionId) => {
    return this.client.get("/exhibitions/" + exhibitionId + "/root/nocache" + "?sd=" + this.subdomain);
  }

  getProjects = () => {
    return this.client.get("/organizations/30bc7460-59aa-4e4c-a882-39693d06cf90/projects");
  }

  getContexts = (pId) => {
    return this.client.get("/projects/" + pId + "/contexts");
  }

  getContextDataSource = (pId, contextId, token) => {
    const eventSource = new EventSource(this.api_url + "/projects/" +
      pId + "/contexts/" + contextId +
      "/events?Authentication-Token=" + token + "&Tenant=clay", { withCredentials: true });
    return eventSource;
  }

  getValueHistory = (valueId) => {
    return this.client
      .get("/values/" + valueId + "/history");
  }

  saveNewKnowledgeEntity = (title, text = "sample", pos, geoloc, type = "narrative", cId, pId) => {
    const pl = {
      "concept": "knowledge", "properties": [
        { attribute: "characteristic", value: { name: "type", value: type }, path: "/characteristic" },
        {
          "attribute": "name",
          "value": { "name": title },
          "path": "/name"
        },
        { "attribute": "description", "value": { "description": text }, "path": "/description" },
      ], "relations": []
    }
    return this.client
      .post("/projects/" + pId + "/contexts/" + cId + "/positions"
        , { position: pos })
      .then(() => {
        return this.client
          .post("/projects/" + pId + "/instances", pl).then(res => {
            const id = res.data.id;
            const pl1 = {
              "projectContextId": cId,
              "position": pos,
              "projectIdOption": pId
            }
            return this.client
              .post("/instances/" + id + "/cells", pl1).then(() => {
                const lng = geoloc[0];
                const lat = geoloc[1];
                const pl3 = { "operations": [{ "op": "add", "attribute": "geolocation", "value": { "data": { "latitude": lat, "longitude": lng }, "index": 2 } }], "relations": [] }
                return this.client.patch("/projects/" + pId + "/instances/" + id, pl3);
              });
          })
      })
  }

  upload = async (file) => {
    const tok = localStorage.getItem("tm-user-token");
    const headers = {
      "Authentication-Token": tok,
      "Content-Type": "multipart/form-data",
      "Accept": "*/*",
    }
    const uploadClient = axios.create({
      withCredentials: true,
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });
    const chunkSize = 1024 * 512;
    const uploadChunk = (chunk, flowId) => {
      const formData = new FormData();
      formData.append("flowChunkNumber", flowId + 1);
      formData.append("flowChunkSize", chunkSize);
      formData.append("flowCurrentChunkSize", chunk.size);
      formData.append("flowTotalSize", file.size);
      formData.append("flowIdentifier", file.size + "-" + file.name.replace(/[^\w\s]/gi, ''));
      formData.append("flowFilename", file.name);
      formData.append("flowRelativePath", file.name);
      formData.append("flowTotalChunks", Math.ceil(file.size / chunkSize));
      formData.append("file", chunk);
      return uploadClient.post("/upload", formData);
    }

    const t = splitFile(file, chunkSize, uploadChunk);
    return t;
  }

  saveNewPlaceEntity = (name = "p1", pos = { q: 0, r: 0 },
    geo = { longitude: 4.880997129849931, latitude: 52.389286595372596 }, image, temporal, cId, pId) => {

    const pl = {
      concept: "place",
      properties: [
        {
          attribute: "name",
          value: { name: name }, // filename
          path: "/name",
        },
        {
          attribute: "geolocation",
          value: {
            longitude: geo.longitude,
            latitude: geo.latitude,
          },
          path: "/geolocation",
        },
        {
          attribute: "image",
          value: {
            href: image,
          },
        },
        {
          attribute: "temporal",
          value: {
            format: {
              date: temporal,
              time: temporal,
              type: "datetime"
            }
          }
        }
      ],
      relations: [],
    };
    // const cId = this.photoWalksSurface;
    return this.client
      .post("/projects/" + pId + "/contexts/" + cId + "/positions"
        , { position: pos })
      .then(() => {
        return this.client
          .post("/projects/" + pId + "/instances", pl).then(res => {
            const id = res.data.id;
            const pl1 = {
              "projectContextId": cId,
              "position": pos,
              "projectIdOption": pId
            }
            return this.client
              .post("/instances/" + id + "/cells", pl1)
          })
      })
  }

  getExhibits = () => {
    return this.client.get("/content/" + this.organization + "/menu?sd=kBase");
  };
}

export const api = new Api();
// https://api.clay.work/projects/"+ pId + "/contexts/c17da8e9-3d81-4508-bfe3-9c2dec6d8747/positions