<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 64 64"
    >
      <g
        id="Group_14853"
        data-name="Group 14853"
        transform="translate(-2372 -120)"
      >
        <path
          id="Rectangle_11861"
          data-name="Rectangle 11861"
          d="M10,0H54A10,10,0,0,1,64,10V64a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z"
          transform="translate(2372 120)"
          fill="#e9e9ee"
        />
        <path
          id="Path_17039"
          data-name="Path 17039"
          d="M0,24H24V21H0ZM0,13.5H24v-3H0ZM0,3H24V0H0Z"
          transform="translate(2392 140.001)"
          fill="#333"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfFilterIcon",
  props: {
    title: {
      type: String,
      default: " Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>