<template>
  <div>
    <div
      :class="[splitView ? 'push-left' : '', 'temporal t-' + cId]"
    >
      <EventsTimeLine :cId="cId" />
    </div>
    <ContextMap
      :cId="cId"
      id="routeMap"
      :zoom="zoom"
      :center="center"
      :filterItems="filterItems"
    />
    <FilterPanel :class="isFilterPanelOpen ? '' : 'hide'" :cId="cId" :filterItems="filterItems"/>
  </div>
</template>

<script>
import ContextMap from "@/components//demo/projectarea/overview/map/ContextMap";
import EventsTimeLine from "@/components/demo/projectarea/overview/temporal/TimeLine.vue";
import FilterPanel from "@/components/demo/projectarea/filter/FilterPanel.vue";
import items from "@/components/demo/projectarea/filter/filter.json";


export default {
  name: "MapView",
  components: {
    ContextMap,
    EventsTimeLine,
    FilterPanel,
  },
  computed: {
    splitView() {
      const v = this.$store.getters.view;
      return v === "detailPanelShown";
    },
    isFilterPanelOpen: function () {
      return this.$store.getters.isFilterPanelOpen;
    },
    filterItems() {
      const jItems = JSON.stringify(items.items);
      return JSON.parse(jItems);
    }
  },
  props: {
    cId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      zoom: 14,
      center: [87, 23],
      isTimeLineOpen: true,
    };
  },
  created() {
  },
};
</script>

<style>
.main {
  flex: 1;
  height: var(--content-ht);
  width: 100%;
}
</style>