<script>
import { Teatree, NodeName } from "vue-teatree";
import { EventBus } from "@/components/EventBus.js";
import TreeNodeToggle from "./TreeNodeToggle.vue";
import FilterTreeIcon from "./FilterTreeIcon.vue";
import items from "@/components/demo/projectarea/filter/filter.json";

export default {
  methods: {
    handleClick(node) {
      node.selected = !node.selected;
      EventBus.$emit("filterSelectionChange", node);
    },
    handleRadioClick(node) {
      node.categorySelected = !node.categorySelected;
      EventBus.$emit("filterSelectionChange", node);
    },
    handleToggleClick(node) {
      node.showChildren = !node.showChildren;
    },
    activeContextChangedHandler(context) {
      const state = this.$store.state;
      const fitems = items.items;

      const clearData = (node) => {
        if (!node.show) return;
        const nonData = node.children.filter(
          (c) => this.nonDataIds.indexOf(c.id) > -1
        );
        node.children.splice(0, node.children.length, ...nonData);
        node.children.forEach((c) => clearData(c));
      };
      const resetSelections = (node) => {
        if (!node.show) return;
        node.children.forEach((c) => {
          const nonData = this.nonDataIds.indexOf(c.id) > -1;
          if (nonData) {
            if (c.categorySelectable) c.categorySelected = true;
          }
        });
        node.children.forEach((c) => resetSelections(c));
      };
      if (context === null) {
        state.filters.forEach((element, i) => {
          clearData(element, fitems[i]);
        });
        console.log(state.filters);
      } else {
        state.filters.forEach((element, i) => {
          resetSelections(element, fitems[i]);
        });
      }
    },
  },
  created() {
    const fitems = items.items;
    this.nonDataIds = [];
    const getId = (item) => {
      this.nonDataIds.push(item.id);
      if (item.children.length) {
        item.children.forEach((it) => getId(it));
      }
    };
    fitems.forEach((i) => {
      getId(i);
    });
    // EventBus.$on("activeContextChanged", this.activeContextChangedHandler);
  },
  data() {
    return {};
  },
  props: {
    cId: {
      type: String,
      default: null,
    },
    filterItems: {
      type: [Object, Array],
      default: null,
    },
  },
  computed: {
    computedFilterItems() {
      return this.filterItems;
    },
    isFilterPanelOpen() {
      return this.$store.getters.isFilterPanelOpen;
    },
    isTimeLineHidden() {
      return !this.$store.getters.isTimeLineOpen;
    },
    getIconSize() {
      const rem = parseInt(getComputedStyle(document.documentElement).fontSize);
      return rem * 1.4;
    },
    contentKey() {
      return this.$store.state.contentKey;
    },
  },
  components: { Teatree, NodeName, TreeNodeToggle, FilterTreeIcon },
  destroyed() {
    EventBus.$off("activeContextChanged", this.activeContextChangedHandler);
  },
};
</script>

<template>
  <div
    :class="{
      'side-panel-container': isTimeLineHidden,
      'side-panel-container pushed-down': !isTimeLineHidden,
    }"
    :key="contentKey"
  >
    <transition name="slide">
      <div
        v-show="isFilterPanelOpen"
        class="side-panel side-panel-filter"
        id="main-filter-panel"
      >
        <Teatree :roots="computedFilterItems">
          <!-- <template slot="tree-node-toggle" slot-scope="{ node }"> </template> -->
          <template slot="node-name" slot-scope="{ node }">
            <circle-icon
              :size="getIconSize"
              v-if="node.categorySelected === false && node.categorySelectable"
              @click="handleRadioClick(node)"
            />
            <circle-slice8-icon
              :size="getIconSize"
              v-else-if="
                node.categorySelected === true && node.categorySelectable
              "
              @click="handleRadioClick(node)"
            />
            <stop-icon
              :size="getIconSize"
              v-else-if="node.categorySelectable === false"
              style="visibility: hidden"
            />

            <FilterTreeIcon
              :icon="node.icon"
              :size="getIconSize / 1.2"
              className="teatree-node-item-icon category-icon"
              v-if="node.icon"
            />

            <stop-icon
              :size="18"
              v-if="
                node.children.length > 0 &&
                node.categorySelected === false &&
                node.categorySelectable === true
              "
              class="teatree-node-item-icon expand-icon category-selectable"
              style="visibility: hidden"
            />
            <stop-icon
              :size="getIconSize"
              v-else-if="node.children.length === 0"
              class="teatree-node-item-icon expand-icon"
              style="visibility: hidden"
            />
            <TreeNodeToggle
              :node="node"
              :class="[
                'expand-icon',
                node.categorySelectable ? 'categorySelectable' : '',
                node.categorySelected === true ? 'categorySelected' : '',
                node.categorySelected === false ? 'categoryNotSelected' : '',
              ]"
              :handleClick="handleToggleClick"
            />
            <NodeName
              :node="node"
              :class="[
                node.className,
                node.categorySelectable ? 'categorySelectable' : '',
                node.categorySelected
                  ? 'categorySelected'
                  : 'categoryNotSelected',
              ]"
              :handleNodeLeftClick="
                node.leaf === true ? handleClick : handleToggleClick
              "
              :handleNodeRightClick="() => {}"
            />
          </template>
        </Teatree>
      </div>
    </transition>
  </div>
</template>

<style>
/* #main-filter-panel.hide {
  display: none;
} */
.teatree {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 0 0 0px;
  background: #333;
  color: #ccc;
}

.teatree > div > .teatree-node > .teatree-node-item {
  margin-bottom: 1vh;
}

.teatree-node .supressed {
  color: #666;
  font-weight: 300;
}

.teatree-node .level-one {
  font-weight: 600;
}

.teatree-node-item {
  display: flex;
  align-items: center;
  height: 1.5rem;
  background: transparent;

  /* hack to make hover the full width of parent  */
  /* padding-left: 100%;
  margin-left: -100%;
  padding-right: 100%;
  margin-right: -100%; */
}

.teatree-node-item + div .teatree-node-item {
  padding-left: 0.9vw;
}

.teatree-node-item + div .teatree-node-item + div {
  padding-left: 0.9vw;
}

.teatree-node-item + div .teatree-node-item + div .teatree-node-item {
  padding-left: 1.8vw;
}

.teatree-node-item .teatree-node-item .teatree-node-item .teatree-node-item {
  padding-left: 40px;
}

.teatree-node-item:hover {
  color: #ffffff;
}

.teatree-node-item-selected,
.teatree-node-item-selected .teatree-node-item-name.leaf,
.teatree-node-item-selected .material-design-icon {
  color: #ffffff;
  font-weight: 600;
}

span.teatree-node-item-name:hover {
  cursor: pointer;
}
.teatree-node-item-name.categorySelectable.categorySelected,
.expand-icon.categorySelectable.categorySelected {
  font-weight: 600;
  color: #fff !important;
}
.teatree-node-item-name.categorySelectable.categoryNotSelected {
  font-weight: 300;
  color: #ccc;
}
.circle-slice8-icon {
  color: #fff !important;
}
.teatree-node-item .material-design-icon {
  padding: 0;
}

.teatree-node-item-icon {
  display: flex;
  align-items: center;
  width: 1.8vw;
  text-align: center;
  margin-left: 0;
}
.teatree-node-item-icon.expand-icon {
  margin-left: 0;
  /* width: 1.8vw; */
}

.teatree-node-item-icon:not(:first-child) {
  margin-left: 0;
}

.teatree-node-item:hover {
  background: #4f4f4f;
}

.teatree-node-item .close-icon,
.teatree-node-item-selected .close-icon {
  color: transparent;
  /* margin-left: 0.5rem; */
}

.teatree-node-item-name {
  display: inline-block;
  font-size: var(--fsize-filterpanel);
  user-select: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-panel-container {
  position: absolute;
  right: 0;
  top: 0;
  width: 18vw;
  height: calc(100vh - var(--topnavbar-ht) - var(--graphicarea-ht) - 2vh);
  z-index: 9;
}
.side-panel-container.hide {
  display: none;
}
.side-panel-container.pushed-down {
  margin-top: var(--temporalbar-ht);
  height: calc(
    100vh - var(--topnavbar-ht) - var(--graphicarea-ht) - 2vh -
      var(--temporalbar-ht)
  );
}

.split-view .side-panel-container {
  right: 50%;
}

.side-panel-filter {
  border-top: none;
  border-bottom: none;
  height: inherit;
}
</style>
