<template>
  <div class="landing">
    <div class="form-container">
      <div class="signin-header">
        <p class="signin-header-notice">Sign in with your email address</p>
      </div>
      <b-form @reset="onReset" autocomplete="off">
        <b-form-group
          id="input-email-group"
          label="E-mail:"
          label-for="input-email"
          class="input-group-label"
        >
          <b-form-input
            class="form-input"
            id="input-email"
            v-model="form.email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-password-group"
          label="Password:"
          label-for="input-password"
          class="input-group-label"
        >
          <b-form-input
            id="input-password"
            v-model="form.password"
            required
            class="form-input"
            type="password"
          ></b-form-input>
        </b-form-group>

        <!-- <b-form-group>
          <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0"
            >Remember me</b-form-checkbox
          >
        </b-form-group> -->

        <div class="buttons-container">
          <b-form-group>
            <b-button
              variant="primary"
              style="width: 100%"
              @click="onSubmit"
              type="submit"
              >SIGN IN</b-button
            >
          </b-form-group>
          <!-- <b-form-group style="text-align: center">
            <b-link @click="onReset" style="color: #4e4e4e;">Reset Password</b-link>
          </b-form-group> -->
        </div>
        <div v-if="loginError">{{ loginError }}</div>
      </b-form>
      <div class="copyright" v-if="copyright">
        <span class="copyright-notice"
          >© Townmaking Institute, usage, replication, and reproduction subject
          to terms with Townmaking and partners.</span
        >
      </div>
    </div>
  </div>
</template>


<script>
import { api } from "@/api/api.js";

export default {
  name: "landing-page",
  props: ["from", "redirect"],
  data() {
    return {
      loginError: null,
      form: {
        email: "",
        password: "",
      },
      copyright: process.env.VUE_APP_SHOW_COPYRIGHT === "true",
    };
  },
  methods: {
    onSubmit() {
      const obj = {
        emailAddress: this.form.email,
        password: this.form.password,
      };
      api.signin(obj).then(
        (response) => {
          if (response.status === 200) {
            this.loginError = null;
            api.getMe().then((res) => {
              if (res.status === 200 && res.data.id) {
                this.$store.commit("setUser", res.data);
                if (this.redirect) {
                  this.$router.push({ path: this.redirect });
                } else {
                  this.$router.push({ name: "demo" });
                }
              } else {
                console.log("Login Failed, res:", res);
              }
            });
          } else {
            console.log("Login Failed, response:", response);
          }
        },
        () => {
          this.loginError = "Login unsuccessful";
        }
      );
    },
    onReset(evt) {
      evt.preventDefault();
    },
  },
  created() {
    // this.api = new Api();
    if (this.$store.getters.user) {
      if (this.redirect) {
        this.$router.push({ path: this.redirect });
      } else {
        this.$router.push({ name: "demo" });
      }
    } else {
      const token = localStorage.getItem("tm-user-token");
      if (token) {
        api.getMe(token).then((res) => {
          if (res.status === 200 && res.data.id) {
            this.$store.commit("setUser", res.data);
            if (this.redirect) {
              this.$router.push({ path: this.redirect });
            } else {
              this.$router.push({ name: "demo" });
            }
          } else {
            localStorage.removeItem("tm-user-token");
          }
        });
      }
    }
  },
};
</script>

<style scoped>
.landing {
  background: url("../assets/img/login.png");
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
}
.landing .form-container {
  width: 375px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem 3rem;
  max-height: calc(100vh - 2rem);
}
.form-group {
  margin-bottom: 0.75rem;
}
.signin-header {
  border-bottom: 2px solid #969696;
  font-size: var(--fsize-topnavbar);
  margin-bottom: 1rem;
}

.signin-header-notice {
  margin-bottom: 0.25rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.275rem 0.75rem;
}

.form-input {
  border: none;
  border-bottom: 1px solid #ccc;
}

.form-input:active,
.form-input:focus {
  border: none;
  box-shadow: none;
  border-bottom: 2px solid;
  border-color: var(--primary);
}

.buttons-container {
  margin-top: 2rem;
}

label {
  font-size: var(--fsize-graphicarea);
  margin-bottom: 0.2rem;
}

.input-group-label {
  font-size: var(--fsize-graphicarea);
}

.copyright-notice {
  font-size: var(--fsize-footer);
  color: #666;
}
</style>