<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 32 32"
    >
      <g xmlns="http://www.w3.org/2000/svg" id="Knowledge">
        <g>
          <path
            class="cls-1"
            d="m16,1c8.27,0,15,6.73,15,15s-6.73,15-15,15S1,24.27,1,16,7.73,1,16,1m0-1C7.16,0,0,7.16,0,16s7.16,16,16,16,16-7.16,16-16S24.84,0,16,0h0Z"
          />
          <polygon
            class="cls-1"
            points="18.51 21 21.02 21 17.18 15.41 20.76 11 18.43 11 14.77 15.57 14.72 15.57 14.72 11 12.63 11 12.63 21 14.72 21 14.72 17.94 15.66 16.79 18.51 21"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfKnowledgeFilterIcon",
  props: {
    title: {
      type: String,
      default: "Knowledge Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
  stroke-width: 0px;
}
</style>