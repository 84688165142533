import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import './assets/scss/custom.scss';
import 'vue-search-select/dist/VueSearchSelect.css'
import * as turf from '@turf/turf';
import Icons from "@/components/demo/icons/VueMaterialIcons.vue";
// import PfIcons from "@/components/demo/icons/PfCustomIcons.vue";
import router from './router';
import resize from "vue-resize-directive";
import store from '@/store.js';
import { BVToastPlugin } from 'bootstrap-vue'



Object.defineProperty(Vue.prototype, '$turf', { value: turf });

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Icons);
// Vue.use(PfIcons);
Vue.use(BVToastPlugin)
Vue.directive('resize', resize);

if (!Array.prototype.last){
  Array.prototype.last = function(){
      return this[this.length - 1];
  };
}

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')



