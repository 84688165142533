var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.contentKey,class:{
    'side-panel-container': _vm.isTimeLineHidden,
    'side-panel-container pushed-down': !_vm.isTimeLineHidden,
  }},[_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFilterPanelOpen),expression:"isFilterPanelOpen"}],staticClass:"side-panel side-panel-filter",attrs:{"id":"main-filter-panel"}},[_c('Teatree',{attrs:{"roots":_vm.computedFilterItems},scopedSlots:_vm._u([{key:"node-name",fn:function(ref){
  var node = ref.node;
return [(node.categorySelected === false && node.categorySelectable)?_c('circle-icon',{attrs:{"size":_vm.getIconSize},on:{"click":function($event){return _vm.handleRadioClick(node)}}}):(
              node.categorySelected === true && node.categorySelectable
            )?_c('circle-slice8-icon',{attrs:{"size":_vm.getIconSize},on:{"click":function($event){return _vm.handleRadioClick(node)}}}):(node.categorySelectable === false)?_c('stop-icon',{staticStyle:{"visibility":"hidden"},attrs:{"size":_vm.getIconSize}}):_vm._e(),(node.icon)?_c('FilterTreeIcon',{attrs:{"icon":node.icon,"size":_vm.getIconSize / 1.2,"className":"teatree-node-item-icon category-icon"}}):_vm._e(),(
              node.children.length > 0 &&
              node.categorySelected === false &&
              node.categorySelectable === true
            )?_c('stop-icon',{staticClass:"teatree-node-item-icon expand-icon category-selectable",staticStyle:{"visibility":"hidden"},attrs:{"size":18}}):(node.children.length === 0)?_c('stop-icon',{staticClass:"teatree-node-item-icon expand-icon",staticStyle:{"visibility":"hidden"},attrs:{"size":_vm.getIconSize}}):_vm._e(),_c('TreeNodeToggle',{class:[
              'expand-icon',
              node.categorySelectable ? 'categorySelectable' : '',
              node.categorySelected === true ? 'categorySelected' : '',
              node.categorySelected === false ? 'categoryNotSelected' : '' ],attrs:{"node":node,"handleClick":_vm.handleToggleClick}}),_c('NodeName',{class:[
              node.className,
              node.categorySelectable ? 'categorySelectable' : '',
              node.categorySelected
                ? 'categorySelected'
                : 'categoryNotSelected' ],attrs:{"node":node,"handleNodeLeftClick":node.leaf === true ? _vm.handleClick : _vm.handleToggleClick,"handleNodeRightClick":function () {}}})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }