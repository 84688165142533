<template>
  <span>
    <span v-for="e in filterEntities(entities)" :key="cId + '-' + e.id">
      <MglMarker :coordinates="e.coordinates" @click="onKnowledgeClick(e)">
        <div slot="marker" :class="['action-icon', 'mapboxgl-marker']">
          <span
            :class="[
              isSelected(e)
                ? 'map-label selected ' + entityType(e)
                : 'map-label ' + entityType(e),
              goto === e.id ? 'blink-2' : '',
            ]"
            v-bind:style="{ 'min-width': iconWidth + 'px' }"
          >
            {{ entityTitle(e) }}
          </span>
          <span v-if="isSelected(e) === true">
            <img
              src="./IconResolutionFocus.svg"
              v-if="entityType(e) === 'resolution'"
              :width="iconWidth"
            />
            <img
              src="./IconForceFocus.svg"
              v-else-if="entityType(e) === 'force'"
              :width="iconWidth"
            />
            <img
              src="./IconNarrativeFocus.svg"
              v-else-if="entityType(e) === 'narrative'"
              :width="iconWidth"
            />
          </span>
          <span v-else>
            <img
              src="./IconResolution.svg"
              v-if="entityType(e) === 'resolution'"
              :width="iconWidth"
            />
            <img
              src="./IconForce.svg"
              v-else-if="entityType(e) === 'force'"
              :width="iconWidth"
            />
            <img
              src="./IconNarrative.svg"
              v-else-if="entityType(e) === 'narrative'"
              :width="iconWidth"
            />
          </span>
        </div>
      </MglMarker>
    </span>
    <MglMarker :coordinates="newEntityPlaceHolder" v-if="newEntityPlaceHolder">
      <div slot="marker" class="action-icon mapboxgl-marker">
        <img src="./IconNewEntity.svg" :width="iconWidth * 2" />
      </div>
    </MglMarker>
    <MglMarker :coordinates="newEntityPlaceHolder" v-if="newEntityPlaceHolder">
      <div slot="marker" class="action-icon mapboxgl-marker">
        <div
          :class="[
            'onmap-menu',
            kMenuXPosition,
            kMenuYPosition,
            wait ? 'wait' : '',
          ]"
        >
          <ol>
            <li>
              Select a Knowledge category
              <ul>
                <li
                  @click="setNewKType('narrative')"
                  :class="newKType === 'narrative' ? 'selected' : ''"
                >
                  <img src="./IconNarrative.svg" /> Narrative
                </li>
                <li
                  @click="setNewKType('force')"
                  :class="newKType === 'force' ? 'selected' : ''"
                >
                  <img src="./IconForce.svg" />
                  Force
                </li>
                <li
                  @click="setNewKType('resolution')"
                  :class="newKType === 'resolution' ? 'selected' : ''"
                >
                  <img src="./IconResolution.svg" />
                  Resolution
                </li>
              </ul>
            </li>
            <li>
              <input
                type="text"
                placeholder="Add Knowledge title"
                @click="(e) => focusOnTitle(e)"
                @keyup="(e) => keyup(e)"
                v-if="isTitleEditable"
              />
              <input type="text" v-else disabled />
            </li>
          </ol>
          <div class="form-button-container">
            <button
              class="btn btn-light"
              v-if="isSaveActive === true"
              @click="save"
              @submit="save"
            >
              Save
            </button>
            <button class="btn btn-secondary" @click="cancelNew">Cancel</button>
          </div>
        </div>
      </div>
    </MglMarker>
    <template v-for="m in filterItem.children">
      <component
        :is="m.component"
        :cId="cId"
        :currentCenter="currentCenter"
        :currentZoom="currentZoom"
        :key="m.id"
        :filterItem="m"
        :v-if="m.component"
      />
    </template>
  </span>
</template>

<script>
import { MglMarker } from "vue-mapbox";
import { EventBus } from "@/components/EventBus.js";
import { api } from "@/api/api.js";
import { ASSET_CATEGORY } from "@/components/demo/constants.js";
import MediaAssets from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/media/MediaAssets.vue";

export default {
  name: "KnowledgeAssets",
  props: {
    embedded: {
      type: Boolean,
      default: false,
    },
    cId: {
      type: String,
      default: null,
    },
    active: {
      type: String,
      default: null,
    },
    currentCenter: {
      type: Array,
      default: null,
    },
    currentZoom: {
      type: Number,
      default: null,
    },
    filterItem: {
      type: [Object, Array],
      default: null,
    },
  },
  components: {
    MglMarker,
    MediaAssets,
  },
  created() {
    this.readEntities();
    const store = this.$store.state;
    let filterSelections = store.selections.filter;
    EventBus.$on("filterSelectionChange", (node) => {
      if (this.cId !== store.activeContext) {
        return;
      }
      if (node.category !== ASSET_CATEGORY.KNOWLEDGE) {
        return;
      } else if (node.type === "media") {
        if (node.leaf) {
          // deselect all k items in filter as media item is selected
          const selectedKnowledge = this.$store.state.content.knowledge;
          if (selectedKnowledge) {
            this.deselectOthers(this.$props.filterItem.children, node.id);
            this.$store.commit("setSelectedKnowledge", null);
          }
        }
      }
      const mh = this.mapHighlights;
      if (node.category !== "media") {
        return;
      }
      if (node.leaf) {
        if (node.selected) {
          mh.splice(0, mh.length);
          this.deselectOthers(this.$props.filterItem.children, node.id);
          const selected = this.entities.filter((e) => e.id === node.id)[0];

          EventBus.$emit("gotoOnMap", selected);
          selected.type = node.type;
          selected.category = node.category;
          this.$store.commit("setSelectedKnowledge", selected);
          EventBus.$emit("changeSelectedKnowledge");
        } else {
          this.$store.commit("setSelectedKnowledge", null);
        }
      } else {
        // category i.e., filter
        // if (node.type !== "media") {
        if (node.categorySelected) {
          filterSelections.conditions.push(node);
          node.children.forEach((c) => (c.show = true));
        } else {
          const i = filterSelections.conditions.findIndex(
            (s) => s.id === node.id
          );
          if (i > -1) filterSelections.conditions.splice(i, 1);
          this.deselectOthers(node.children, node.id);
          const idx = mh.findIndex((h) => h.category === node.type);
          if (idx > -1) mh.splice(idx, 1);
          const sk = this.$store.state.content.knowledge;
          if (!sk) return;
          if (sk.category === node.type || sk.type === node.type) {
            this.$store.commit("setSelectedKnowledge", null);
          }
          node.children.forEach((c) => (c.show = false));
          node.showChildren = false;
        }
        // }
      }
    });
    this.$props.filterItem.children.forEach((element) => {
      if (element.show) filterSelections.conditions.push(element);
    });
    EventBus.$on("mapDblClick", ($e) => {
      if (this.cId !== this.$store.getters.activeContext) return;
      this.kMenuXPosition =
        (100 * $e.mapboxEvent.originalEvent.offsetX) / window.innerWidth > 75
          ? "left"
          : "right";
      const yOffset =
        (100 * $e.mapboxEvent.originalEvent.offsetY) / window.innerHeight;
      this.kMenuYPosition =
        yOffset < 45 ? "bottom" : yOffset < 85 ? "top" : "bottom";
      this.isSaveActive = true;
      this.isTitleEditable = true;
      this.$store.commit("hideDetailPanel");
      EventBus.$emit("viewChange");
      const placeHolder = [
        $e.mapboxEvent.lngLat.lng,
        $e.mapboxEvent.lngLat.lat,
      ];
      this.newEntityPlaceHolder = placeHolder;
      this.$store.commit("setSelectedKnowledge", {});
    });
    EventBus.$on("timelineDotClick", (dot) => {
      if (this.cId !== this.$store.getters.activeContext) return;
      EventBus.$emit("gotoOnMap", dot.entity);
      const _this = this;
      this.goto = dot.entity.id;
      setTimeout(function () {
        _this.goto = null;
      }, 2000);
    });
    EventBus.$on("mapEscKey", () => {
      if (this.cId !== this.$store.getters.activeContext) return;
      this.newEntityPlaceHolder = null;
    });
    EventBus.$on("activeContextChanged", () => {
      if (this.cId === this.$store.state.activeContext) {
        EventBus.$emit("gotoOnMap", this.entities[0]);
      }
    });
  },
  computed: {
    iconWidth() {
      const center = this.currentCenter;
      const zoom = this.currentZoom;
      var tmp =
        (65000 * Math.cos((center[1] * Math.PI) / 180)) / Math.pow(2, zoom);
      return 36 / tmp;
    },
  },
  data() {
    return {
      isSaveActive: false,
      newTitle: "",
      newKType: "",
      wait: false,
      isTitleEditable: true,
      references: [],
      tempPos: null,
      clayPopulation: [],
      cells: [],
      tempValue: null,
      newEntityPlaceHolder: null,
      entities: [],
      mapHighlights: [],
      goto: null,
    };
  },
  methods: {
    readEntities() {
      const cId = this.cId;
      const ds = api.getContextDataSource(
        this.$store.state.activeProject.value,
        cId,
        localStorage.getItem("tm-user-token")
      );
      ds.addEventListener("message", (event) => {
        if (this.$store.state.ignoreDataSource) return;
        const msg = JSON.parse(event.data);
        if (msg.name === "instance") {
          if (msg.data.tags.includes("reference")) {
            this.addReference(msg.data);
            this.ref[msg.data.id] = msg.data.properties[1].value.data;
          } else {
            this.addEntity(msg.data);
          }
        } else if (msg.name === "instanceCell") {
          this.addCell(msg.data);
        } else if (msg.name === "initialized") {
          this.updateContext();
          EventBus.$emit("dataInitialized", cId, this.entities);
          this.entities[0] && EventBus.$emit("gotoOnMap", this.entities[0]);
        } else if (
          msg.name == "instanceValueAdded" &&
          msg.data.property.attributeRef.handle === "geolocation"
        ) {
          this.addEntityFromTemp(msg.data.property.value.data);
          this.addCell(msg.data.id);
        } else if (msg.name === "positionUpdate") {
          this.keepTempPosition(msg.data.position);
        }
      });
    },
    addEntity(value) {
      let gl = value.coordinates;
      if (!gl) {
        gl = value.properties.filter(
          (p) => p.attributeRef.handle === "geolocation"
        )[0];
      }

      if (gl) {
        if (!value.coordinates) {
          value.coordinates = [gl.value.data.longitude, gl.value.data.latitude];
        }
        const entities = this.entities;
        let kType;
        const kTypeProp = value.properties.filter((p) => {
          return p.value.data.name === "type";
        })[0];
        if (!kTypeProp) {
          // could be some random entity
          return;
        } else {
          kType = kTypeProp.value.data.value;
        }
        entities.push(value);
        const eIndex = { narrative: 0, force: 1, resolution: 2 };
        const entityForFilter = {
          name: value.properties.filter(
            (p) => p.value.attribute.handle === "name"
          )[0].value.data.name,
          show: true,
          showChildren: false,
          selected: false,
          children: [],
          disabled: false,
          type: kType,
          id: value.id,
          category: "knowledge",
          className: "leaf",
          leaf: true,
        };
        const fi = this.$props.filterItem.children[eIndex[kType]];
        if (fi) fi.children.push(entityForFilter);
      } else {
        this.tempValue = value;
      }
    },
    addReference(obj) {
      this.references[obj.value.id] = obj.value;
    },
    keepTempPosition(pos) {
      this.tempPos = pos;
    },
    addCell(cell) {
      const state = this.$store.state;
      const p = cell.position ? cell.position : this.tempPos;
      if (p.q > -1 && p.r > -1) {
        this.clayPopulation[p.q] = this.clayPopulation[p.q] || [];
        this.clayPopulation[p.q][p.r] = cell.id;
      }
      this.cells.push(cell);
      state.tempPos = null;
    },
    addEntityFromTemp(gl) {
      const value = { ...this.tempValue };
      value.coordinates = [gl.longitude, gl.latitude];
      this.addEntity(value);
      this.tempValue = null;
      this.newEntityPlaceHolder = [];
    },
    updateContext() {
      this.$store.state.contentKey = Math.random();
    },
    clearContext() {
      const state = this.$store.state;
      const sEntities = state.map.entities;
      sEntities.splice(0, sEntities.length);
      this.$store.commit("setSelectedKnowledge", null);
    },
    filterEntities(entities) {
      const eType = ["narrative", "force", "resolution"];
      const filtered = this.$store.state.selections.filter.conditions.map(
        (n) => n.type
      );
      return entities.filter((v) => {
        const type = v.properties.filter(
          (p) => p.attributeRef.handle === "characteristic"
        )[0].value.data.value;

        return eType.indexOf(type) > -1 && filtered.indexOf(type) > -1;
      });
    },
    focusOnTitle(e) {
      e.target.focus();
    },
    keyup(e) {
      if (e.keyCode === 27) {
        this.isTitleEditable = false;
        this.isSaveActive = false;
      } else if (e.keyCode === 13) {
        this.isTitleEditable = false;
        this.newTitle = e.target.value;
        this.isSaveActive = false;
        this.save();
      } else {
        this.newTitle = e.target.value;
        this.isSaveActive = true;
      }
    },
    entityTitle(v) {
      const p = v.properties.filter((p) => p.attributeRef.handle === "name")[0];
      return p.value.data.name;
    },
    entityType(v) {
      return v.properties.filter(
        (p) => p.attributeRef.handle === "characteristic"
      )[0].value.data.value;
    },
    instanceValue(instanceId) {
      const v = this.$store.getters.entityById(instanceId);
      if (v) return v;
      else return this.references[instanceId];
    },
    onKnowledgeClick(knowledge) {
      const filterItems = this.filterItem.children;
      this.selectOnlyOne(filterItems, knowledge.id);
      if (
        this.$store.state.content.knowledge &&
        this.$store.state.content.knowledge.id === knowledge.id
      ) {
        this.$store.commit("setSelectedKnowledge", null);
        this.$store.state.view = "detailPanelHidden";
      } else {
        this.$store.commit("gotoDetailViewMode");
        this.$store.state.activeDetailsTab = "knowledge";
        this.$store.commit("setSelectedKnowledge", knowledge);
      }
      EventBus.$emit("changeSelectedKnowledge");
      EventBus.$emit("viewChange");
    },
    save() {
      this.wait = true;
      this.isTitleEditable = false;
      const next = function (pos) {
        const { q, r } = pos;
        if (q === r) {
          if (q > 0) {
            return { q: q - 1, r: r };
          } else {
            return { q: q + 1, r: 0 };
          }
        } else if (q > r) {
          return { q: q, r: r + 1 };
        } else {
          // q < r
          if (q === 0) return { q: r + 1, r: 0 };
          else return { q: q - 1, r: r };
        }
      };
      let n = { q: 0, r: 0 };
      const cp = this.clayPopulation;
      while (cp[n.q] && cp[n.q][n.r]) {
        n = next(n);
      }
      const nextEmpty = n;
      const type = this.newKType;
      api
        .saveNewKnowledgeEntity(
          this.newTitle,
          this.newTextContent,
          nextEmpty,
          this.newEntityPlaceHolder,
          type,
          this.cId,
          this.$store.state.activeProject.value
        )
        .then((r) => {
          this.wait = false;
          this.isSaveActive = false;
          this.newTitle = "New Title";
          this.newTextContent = "";
          const props = {};
          r.data.properties.map((p) => {
            if (p.attributeRef.handle === "image") {
              props[p.attributeRef.handle] = p.value.data.href;
            } else {
              props[p.attributeRef.handle] =
                p.value.data[p.attributeRef.handle];
            }
          });
          this.newEntityPlaceHolder = null;
        })
        .catch(() => {
          this.$bvToast.show("error");
        });
    },
    setNewKType(type) {
      this.newKType = type;
    },
    cancelNew() {
      this.newEntityPlaceHolder = null;
    },
    isSelected(e) {
      const selected = this.$store.state.content.knowledge;
      return selected && selected.id === e.id;
    },
    deselectOthers(children, id) {
      children.forEach((ch) => {
        if (ch.children.length) {
          return this.deselectOthers(ch.children, id);
        }
        if (ch.id !== id) {
          ch.selected = false;
        }
      });
    },
    selectOnlyOne(children, id) {
      children.forEach((ch) => {
        if (ch.children.length) {
          return this.selectOnlyOne(ch.children, id);
        }
        if (ch.id !== id) {
          ch.selected = false;
        } else {
          ch.selected = !ch.selected;
        }
      });
    },
  },
  destroyed() {
    EventBus.$off("filterSelectionChange");
    EventBus.$off("mapDblClick");
    EventBus.$off("timelineDotClick");
  },
};
</script>

<style>
.action-icon {
  cursor: pointer;
}

.router-link-active .selected {
  display: block;
}

.onmap-menu {
  width: 25vw;
  border: solid 1px white;
  display: inline-block;
  color: white;
  padding: 1vw 0;
  background: #333;
  position: absolute;
  z-index: 9999999;
}

.onmap-menu.wait {
  cursor: wait;
}
.onmap-menu.right {
  left: 1vw;
}
.onmap-menu.left {
  right: 1vw;
}
.onmap-menu.top {
  bottom: 100%;
}

.onmap-menu.wait .btn {
  cursor: wait !important;
}

.onmap-menu ol > li {
  margin-bottom: 2vw;
}

.onmap-menu ul {
  list-style-type: none;
  padding: 0;
  line-height: 2vw;
}

.onmap-menu ul > li {
  width: 10vw;
  padding: 0 1vw;
}

.onmap-menu ul > li.selected {
  background: #777;
}

.onmap-menu ul img {
  width: 1.5vw;
  margin-right: 1vw;
}

.onmap-menu input {
  background: transparent;
  border: 0;
  box-shadow: none;
  border-bottom: solid 1px grey;
  width: 90%;
  caret-color: white;
  color: white;
}

.onmap-menu input:active,
.onmap-menu input:focus {
  background: transparent;
  box-shadow: none;
  border: none;
}

.form-button-container {
  display: flex;
  width: 100%;
  padding: 0 10% 0 40px;
  justify-content: space-between;
}

.form-button-container .btn {
  width: 8vw;
  height: 2vw;
  font-size: var(--fsize-map);
  line-height: 100%;
}

.map-label.selected {
  display: inline;
}
.write-enabled .mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass {
  cursor: url("./IconCursor.svg") 17 17, auto;
}
/* .map-label.narrative {
  background: #3cf;
}
.map-label.force {
  background: #fd0;
}
.map-label.resolution {
  background: #9c3;
} */
</style>