<template>
  <div class="photowalk-tab">
    <b-toast id="error" title="No write permissions" :solid="true">
      Please request admin team to allow access.
    </b-toast>
    <div class="newphotowalk">
      <DropFile :cId="cId" />
    </div>
  </div>
</template>

<style>
#map-area > .card {
  background-color: #e9e9e9;
}

.card-header {
  padding: 0px;
  height: var(--graphicarea-ht);
}

.card-header-tabs {
  margin-bottom: 0;
  flex-flow: nowrap;
}

.card-body {
  padding: 0px;
}
.newphotowalk {
  height: inherit;
}
</style>


<script>
// import { EventBus } from "@/components/EventBus.js";
import DropFile from "./DropFile.vue";

export default {
  name: "NewPhotowalkTab",
  components: { DropFile },
  created() {},
  computed: {},
  methods: {},
  data: function () {
    return {};
  },
  props: {
    cId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.content-header-title-icon {
  width: 5vh;
  line-height: 5vh;
  border-right: solid 1px white;
  display: inline-block;
  text-align: center;
}
.b-toast {
  max-width: 100vw;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 50vw;
  top: 10vh;
  left: 25vw;
}
</style>