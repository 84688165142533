<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 32 32"
    >
      <g id="Knowledge">
        <g>
          <circle class="st0" cx="16" cy="16" r="16" />
          <path
            class="st1"
            d="M20.9,14.7c-0.3,0.3-0.6,0.5-1,0.7c-0.7,0.3-1.6,0.4-2.6,0.1c-0.4-0.1-0.8-0.2-1.3-0.4    c-0.4-0.1-0.9-0.3-1.3-0.4c-0.4-0.1-0.9-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.7l0,0v2.3l0.2-0.2    c0.3-0.3,0.6-0.5,1-0.7s0.8-0.2,1.4-0.2c0.4,0,0.8,0,1.2,0.2c0.4,0.1,0.8,0.2,1.2,0.4c0.4,0.1,0.9,0.3,1.3,0.4    c0.4,0.1,0.9,0.2,1.3,0.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1-0.7l0,0v-2.3C21.1,14.5,20.9,14.7,20.9,14.7z"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfForcesFilterIcon",
  props: {
    title: {
      type: String,
      default: "Forces Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
.st0 {
  fill: #ffdd00;
}
.st1 {
  fill: #333333;
}
</style>