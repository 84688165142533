var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._l((_vm.filterEntities(_vm.entities)),function(e){return _c('span',{key:_vm.cId + '-' + e.id},[_c('MglMarker',{attrs:{"coordinates":e.coordinates},on:{"click":function($event){return _vm.onKnowledgeClick(e)}}},[_c('div',{class:['action-icon', 'mapboxgl-marker'],attrs:{"slot":"marker"},slot:"marker"},[_c('span',{class:[
            _vm.isSelected(e)
              ? 'map-label selected ' + _vm.entityType(e)
              : 'map-label ' + _vm.entityType(e),
            _vm.goto === e.id ? 'blink-2' : '' ],style:({ 'min-width': _vm.iconWidth + 'px' })},[_vm._v(" "+_vm._s(_vm.entityTitle(e))+" ")]),(_vm.isSelected(e) === true)?_c('span',[(_vm.entityType(e) === 'resolution')?_c('img',{attrs:{"src":require("./IconResolutionFocus.svg"),"width":_vm.iconWidth}}):(_vm.entityType(e) === 'force')?_c('img',{attrs:{"src":require("./IconForceFocus.svg"),"width":_vm.iconWidth}}):(_vm.entityType(e) === 'narrative')?_c('img',{attrs:{"src":require("./IconNarrativeFocus.svg"),"width":_vm.iconWidth}}):_vm._e()]):_c('span',[(_vm.entityType(e) === 'resolution')?_c('img',{attrs:{"src":require("./IconResolution.svg"),"width":_vm.iconWidth}}):(_vm.entityType(e) === 'force')?_c('img',{attrs:{"src":require("./IconForce.svg"),"width":_vm.iconWidth}}):(_vm.entityType(e) === 'narrative')?_c('img',{attrs:{"src":require("./IconNarrative.svg"),"width":_vm.iconWidth}}):_vm._e()])])])],1)}),(_vm.newEntityPlaceHolder)?_c('MglMarker',{attrs:{"coordinates":_vm.newEntityPlaceHolder}},[_c('div',{staticClass:"action-icon mapboxgl-marker",attrs:{"slot":"marker"},slot:"marker"},[_c('img',{attrs:{"src":require("./IconNewEntity.svg"),"width":_vm.iconWidth * 2}})])]):_vm._e(),(_vm.newEntityPlaceHolder)?_c('MglMarker',{attrs:{"coordinates":_vm.newEntityPlaceHolder}},[_c('div',{staticClass:"action-icon mapboxgl-marker",attrs:{"slot":"marker"},slot:"marker"},[_c('div',{class:[
          'onmap-menu',
          _vm.kMenuXPosition,
          _vm.kMenuYPosition,
          _vm.wait ? 'wait' : '' ]},[_c('ol',[_c('li',[_vm._v(" Select a Knowledge category "),_c('ul',[_c('li',{class:_vm.newKType === 'narrative' ? 'selected' : '',on:{"click":function($event){return _vm.setNewKType('narrative')}}},[_c('img',{attrs:{"src":require("./IconNarrative.svg")}}),_vm._v(" Narrative ")]),_c('li',{class:_vm.newKType === 'force' ? 'selected' : '',on:{"click":function($event){return _vm.setNewKType('force')}}},[_c('img',{attrs:{"src":require("./IconForce.svg")}}),_vm._v(" Force ")]),_c('li',{class:_vm.newKType === 'resolution' ? 'selected' : '',on:{"click":function($event){return _vm.setNewKType('resolution')}}},[_c('img',{attrs:{"src":require("./IconResolution.svg")}}),_vm._v(" Resolution ")])])]),_c('li',[(_vm.isTitleEditable)?_c('input',{attrs:{"type":"text","placeholder":"Add Knowledge title"},on:{"click":function (e) { return _vm.focusOnTitle(e); },"keyup":function (e) { return _vm.keyup(e); }}}):_c('input',{attrs:{"type":"text","disabled":""}})])]),_c('div',{staticClass:"form-button-container"},[(_vm.isSaveActive === true)?_c('button',{staticClass:"btn btn-light",on:{"click":_vm.save,"submit":_vm.save}},[_vm._v(" Save ")]):_vm._e(),_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.cancelNew}},[_vm._v("Cancel")])])])])]):_vm._e(),_vm._l((_vm.filterItem.children),function(m){return [_c(m.component,{key:m.id,tag:"component",attrs:{"cId":_vm.cId,"currentCenter":_vm.currentCenter,"currentZoom":_vm.currentZoom,"filterItem":m,"v-if":m.component}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }