import Vuex from 'vuex'
import Vue from 'vue';


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    modules: ["knowledge", "media"],
    user: null,
    projects: [],
    activeProject: null,
    contexts: [],
    pwContexts: [],
    openContexts: [],
    activeContext: null,
    activePwCId: null,
    publications: [],
    pwPubMenu: [],// name id pairs
    pwPublications: [],
    activePwClayPopulation: [],
    ignoreDataSource: false,
    map: {
      entities: [],
      newEntityPlaceHolder: null,
    },
    content: {
      knowledge: {},
      detailTabMode: null,
      activeDetailsTab: "asset"
    },
    view: 'detailPanelHidden',
    filterPanelHidden: true,
    timeLineHidden: false,
    activeTab: "projects",
    selections: {
      filter: {
        conditions: [] // mostly a category that is selected in the filter panel
      },
    },
    contentKey: Math.random()
  },
  mutations: {
    toggleSplitView(state) {
      state.view = state.view === "detailPanelHidden" ? "detailPanelShown" : "detailPanelHidden";
      // if (state.view === "detailPanelShown") state.filterPanelHidden = true;
    },
    toggleFilterView(state) {
      state.filterPanelHidden = !state.filterPanelHidden;
      // if (!state.filterPanelHidden) state.view = "detailPanelHidden";
    },
    hideDetailPanel (state) {
      state.view = "detailPanelHidden";
    },
    showOnlyMap(state) {
      state.view = "detailPanelHidden";
      state.filterPanelHidden = true;
    },
    setSelectedKnowledge(state, k) {
      state.content.knowledge = k;
    },
    toggleTimeLine(state) {
      state.timeLineHidden = !state.timeLineHidden;
    },
    setActiveTab: (state, tab) => {
      state.activeTab = tab;
    },
    gotoDetailViewMode: (state) => {
      state.content.activeDetailsTab = "knowledge";
      state.content.detailTabMode = "view";
      state.view = "detailPanelShown";
    },
    setNewEntityPlaceHolder: (state, obj) => {
      if (obj === null) {
        state.map.newEntityPlaceHolder = null;
      } else {
        const placeholder = obj.ph;
        const cId = obj.cId;
        state.map.newEntityPlaceHolder = placeholder;
        state.activeContext = cId;
      }
    },
    setActiveDetailsTab: (state, tab) => {
      state.content.activeDetailsTab = tab;
    },
    setActiveContext: (state, cId) => {
      state.activeContext = cId;
    },
    setActiveProject: (state, obj) => {
      state.activeProject = obj;
    },
    addToOpenContexts: (state, con) => {
      const i = state.openContexts.findIndex(oc => oc.value === con.value);
      if (i < 0) state.openContexts.push(con);
    },
    closeContext: (state, con) => {
      const cId = con.value;
      state.openContexts.splice(state.openContexts.findIndex(c => c.value === cId), 1);
    },
    setUser: (state, user) => {
      state.user = user;
    }
  },
  getters: {
    contexts: state => state.contexts,
    openContexts: state => state.openContexts,
    activeContext: state => state.activeContext,
    activeProject: state => state.activeProject,
    user: state => state.user,
    entities(state)  {
      return state.map.entities;
    },
    entityById: (state) => (id) => {
      return state.map.entities.filter(e => e.id === id)[0];
    },
    view: (state) => {
      return state.view;
    },
    knowledgeType: (state) => {
      const t = state.content.knowledge.properties.filter(p => p.attributeRef.handle === "characteristic")[0];
      return t ? t.value.data.value : null;
    },
    knowledgeName: (state) => {
      const t = state.content.knowledge.properties.filter(p => p.attributeRef.handle === "name")[0];
      return t ? t.value.data.name : null;
    },
    contentElements: (state) => {
      const props = state.content.knowledge.properties;
      let elements = [];
      props.forEach(p => {
        if (p.attributeRef.handle === "image") {
          elements.push({
            type: "image",
            value: p.value.data.href
          })
        } else if (p.attributeRef.handle === "description") {
          elements.push({
            type: "text",
            value: p.value.data.description
          })
        }
      });
      return elements;
    },
    knowledge: (state) => {
      return state.content.knowledge;
    },
    isFilterPanelOpen: (state) => {
      return !state.filterPanelHidden;
    },
    isTimeLineOpen: (state) => {
      return !state.timeLineHidden;
    },
    activeTab: (state) => {
      return state.activeTab;
    },
    detailTabMode: (state) => {
      return state.content.detailTabMode;
    },
    newEntityPlaceHolder: (state) => {
      return state.map.newEntityPlaceHolder;
    },
    clayPopulation: (state) => (cId) => {
      let id = cId;
      if (!id) id = state.activeContext;
      return state.map.clayPopulation;
    },
    tempPos: (state) => {
      return state.tempPos;
    },
    activeDetailsTab: (state) => {
      return state.content.activeDetailsTab;
    },
    projects: (state) => {
      return state.projects;
    }
  }
})

export default store;