import { render, staticRenderFns } from "./PfAddSurfaceIcon.vue?vue&type=template&id=0207a97a&scoped=true&functional=true&"
import script from "./PfAddSurfaceIcon.vue?vue&type=script&lang=js&"
export * from "./PfAddSurfaceIcon.vue?vue&type=script&lang=js&"
import style0 from "./PfAddSurfaceIcon.vue?vue&type=style&index=0&id=0207a97a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0207a97a",
  null
  
)

export default component.exports