<template>
  <div id="project-area" :class="isFilterPanelOpen ? 'filter-panel-open' : ''">
    <div class="project-area-header">
      <b-card no-body>
        <b-tabs card>
          <template #tabs-start v-if="activeProject">
            <pf-add-surface-icon
              class="project-area-header-icon"
              :size="getIconSize"
              @click="toggleContextSelector"
            />
            <context-selector :contexts="getIdleContexts" />
          </template>
          <b-tab
            v-for="c in getOpenContexts"
            :key="c.value"
            @click="notifyContextSelector(c)"
            :active="c.value === active"
          >
            <template #title>
              {{ c.text }}
              <pf-close-surface-icon
                :size="getIconSize"
                @click="removeOpenContext(c)"
                :fillColor="c.value === active ? '#333' : '#888'"
              />
            </template>

            <map-view :cId="c.value" />
          </b-tab>
          <template #tabs-end>
            <span class="push-right" />
            <b-nav-item
              href="#"
              role="presentation"
              @click="toggleFilter"
              :class="[
                isFilterPanelOpen ? 'right-controls active' : 'right-controls',
                splitView ? 'middle' : '',
              ]"
            >
              <pf-filter-icon :size="getIconSize" v-if="!isFilterPanelOpen" />
              <pf-filter-open-icon :size="getIconSize" v-else />
            </b-nav-item>

            <b-nav-item
              href="#"
              role="presentation"
              @click="toggleSplitView"
              :class="[splitView ? 'right-controls active' : 'right-controls']"
            >
              <pf-detail-panel-open-icon :size="getIconSize" v-if="splitView" />
              <pf-detail-panel-icon :size="getIconSize" v-else />
            </b-nav-item>
          </template>
        </b-tabs>
      </b-card>
    </div>
    <div
      class="right-half"
      v-bind:style="{ display: detailPanelShown ? 'block' : 'none' }"
    >
      <DetailPanel :cId="active" />
    </div>
  </div>
</template>

<script>
import DetailPanel from "@/components/demo/projectarea/detailsarea/DetailPanel.vue";
import ContextSelector from "./header/ContextSelector.vue";
import PfFilterIcon from "./filter/PfFilterIcon.vue";
import PfFilterOpenIcon from "./filter/PfFilterOpenIcon.vue";
import PfAddSurfaceIcon from "./PfAddSurfaceIcon.vue";
import PfCloseSurfaceIcon from "./PfCloseSurfaceIcon.vue";
import MapView from "@/components/demo/projectarea/overview/MapView.vue";
import { EventBus } from "@/components/EventBus.js";
import PfDetailPanelIcon from "../icons/PfDetailPanelIcon.vue";
import PfDetailPanelOpenIcon from "../icons/PfDetailPanelOpenIcon.vue";

export default {
  name: "project-area",
  components: {
    DetailPanel,
    ContextSelector,
    PfFilterIcon,
    PfFilterOpenIcon,
    PfAddSurfaceIcon,
    PfCloseSurfaceIcon,
    PfDetailPanelIcon,
    PfDetailPanelOpenIcon,
    MapView,
  },
  props: {
    pId: {
      type: String,
      default: null,
    },
  },
  computed: {
    detailPanelShown: function () {
      return this.$store.getters.view === "detailPanelShown";
    },
    activeProject() {
      const p = this.$store.state.activeProject;
      return p;
    },
    splitView() {
      const v = this.$store.getters.view;
      return v === "detailPanelShown";
    },
    getAddIconSize() {
      return document.documentElement.clientHeight * 0.045;
    },
    getIconSize() {
      return document.documentElement.clientHeight * 0.05;
    },
    getIdleContexts() {
      const allcon = this.$store.getters.contexts;
      const ocon = this.$store.getters.openContexts;
      return allcon.filter(
        (ac) => ocon.filter((o) => o.value === ac.value).length <= 0
      );
    },
    getOpenContexts() {
      return this.$store.getters.openContexts;
    },
    active() {
      return this.$store.getters.activeContext;
    },
    step() {
      const oc = this.$store.getters.openContexts;
      const ac = this.$store.getters.activeContext;
      let i = 0;
      if (ac) i = oc.reverse().findIndex((c) => c.value === ac.value);
      return i;
    },
    isFilterPanelOpen: function () {
      return this.$store.getters.isFilterPanelOpen;
    },
  },
  methods: {
    toggleSplitView() {
      this.$store.commit("toggleSplitView");
      EventBus.$emit("viewChange");
    },
    toggleFilter() {
      this.$store.commit("toggleFilterView");
      EventBus.$emit("viewChange");
    },
    toggleTimeLine() {
      this.$store.commit("toggleTimeLine");
    },
    notifyContextSelector(active) {
      EventBus.$emit("requestACchange", active);
    },
    removeOpenContext(con) {
      const cId = con.value;
      this.$store.commit("closeContext", con);
      const a = this.$store.getters.activeContext;
      if (a === cId) {
        const oc = this.$store.getters.openContexts;
        if (oc.length) {
          EventBus.$emit("requestACchange", oc[0]);
        } else {
          EventBus.$emit("requestACchange", null);
        }
      }
    },
    toggleContextSelector() {
      EventBus.$emit("toggleContextSelector");
    },
    getClayUrl() {
      let url = "https://clay.work/#/";
      if (this.pId) {
        url += "projects/" + this.pId;
        if (this.active) {
          url += "/contexts/" + this.active;
        }
      }
      return url;
    },
  },
  created() {},
  data() {
    return {};
  },
  destroyed() {},
};
</script>
<style>
.split-view #project-area .route-map-container {
  width: 50%;
}

/* .split-view #project-area.filter-panel-open .route-map-container{
  width: calc(100% - (50%));
} */

#project-area {
  width: 100%;
  position: relative;
}
.right-half {
  width: 50%;
  position: absolute;
  top: var(--graphicarea-ht);
  right: 0;
  background: white;
  height: var(--map-ht);
}

.project-area-header {
  position: relative;
}
.project-area-header .right-controls .material-design-icon:hover {
  background: transparent;
}
#project-area > .card {
  background-color: #e9e9e9;
}

.card-header {
  padding: 0px;
  height: var(--graphicarea-ht);
}

.card-header-tabs {
  margin-bottom: 0;
  flex-flow: nowrap;
}

.card-body {
  padding: 0px;
}

.nav-link {
  padding: 0;
  line-height: var(--graphicarea-ht);
  height: var(--graphicarea-ht);
  font-size: var(--fsize-graphicarea);
  text-align: center;
  color: #888;
}

.project-area-header .nav-link.active,
.project-area-header .nav-link.active .close-icon {
  color: #333;
  font-weight: 800;
}
.project-area-header .nav-link.active .close-icon:hover {
  color: white;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
}

.nav-tabs .project-area-header-icon:first-child {
  background: #e9e9ee;
  width: 3vw;
  padding-left: 0;
  padding-right: 0;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  text-align: center;
  margin-left: 10px;
  color: #333;
}

.nav-tabs .project-area-header-icon:first-child:hover {
  color: #fff;
}

.nav-tabs .nav-link:hover {
  color: #888;
  background-color: #e9e9ee;
}

.nav-link.active:hover {
  color: #333;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: 0px;
}

a:hover {
  color: initial;
}

.nav-link {
  padding: 0vh 1.5vw;
  line-height: var(--graphicarea-ht);
  height: var(--graphicarea-ht);
}
.nav-tabs .nav-link {
  border: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #e9e9ee;
  border: 0px;
  padding-right: 1vw;
}
.nav-item.right-controls:nth-child(1) {
  margin-left: auto;
}
.right-controls.nav-item:nth-last-child(3).middle {
  margin-right: calc(50vw - 2 * var(--graphicarea-ht));
}
.right-controls.nav-item:nth-last-child(2).middle {
  margin-right: calc(50vw - var(--graphicarea-ht));
}
.right-controls .nav-link {
  border: 0px;
  padding: 0;
}

.right-controls.active .nav-link .material-design-icon {
  background: #333;
  color: #fff;
}
.card-header .material-design-icon {
  display: inline-block;
  line-height: var(--graphicarea-ht);
  padding: 0;
  width: var(--graphicarea-ht);
}
.card-header .material-design-icon svg {
  padding: 0;
  height: var(--graphicarea-ht);
}
.card-header .right-controls .material-design-icon svg {
  padding: 0;
}
.right-controls .nav-link .material-design-icon {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: var(--graphicarea-ht);
  text-align: center;
}
.card-header-tabs {
  margin-right: 0px;
}
.ui.selection.dropdown {
  position: absolute;
  top: 5vh;
  z-index: 12;
  width: 20vw;
}
iframe {
  width: 200%;
  height: 200%;
  zoom: 0.75;
  transform-origin: 0 0;
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}
.left-container {
  overflow: hidden;
  position: relative;
  height: 70vh;
}
</style>