<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-air-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 64 64"
    >
      <g id="Group_14851" data-name="Group 14851" transform="translate(0 -120)">
        <path
          id="Rectangle_11856"
          data-name="Rectangle 11856"
          d="M10,0H64a0,0,0,0,1,0,0V64a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z"
          transform="translate(0 120)"
          fill="#e9e9ee"
        />
        <g
          id="Group_14358"
          data-name="Group 14358"
          transform="translate(-815.76 106.24)"
        >
          <line
            id="Line_988"
            data-name="Line 988"
            y2="24"
            transform="translate(844.76 33.76)"
            fill="none"
            stroke="#000"
            stroke-width="4"
          />
          <line
            id="Line_989"
            data-name="Line 989"
            x2="24"
            transform="translate(832.76 45.76)"
            fill="none"
            stroke="#000"
            stroke-width="4"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfAirIcon",
  props: {
    title: {
      type: String,
      default: "Air icon",
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.st0 {
  opacity: 0.3;
}
.st1 {
  fill: none;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
}
</style>