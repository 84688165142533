<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-air-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 64 64"
    >
      <g
        id="Group_14849"
        data-name="Group 14849"
        transform="translate(-294 -120)"
      >
        <rect
          id="Rectangle_11857"
          data-name="Rectangle 11857"
          width="64"
          height="64"
          transform="translate(294 118)"
          fill="#EB4E29"
        />
        <g
          id="Group_14359"
          data-name="Group 14359"
          transform="translate(-238.978 -477.723) rotate(45)"
        >
          <line
            id="Line_988"
            data-name="Line 988"
            y2="24"
            transform="translate(844.76 33.76)"
            fill="none"
            stroke="#fff"
            stroke-width="4"
          />
          <line
            id="Line_989"
            data-name="Line 989"
            x2="24"
            transform="translate(832.76 45.76)"
            fill="none"
            stroke="#fff"
            stroke-width="4"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfAirIcon",
  props: {
    title: {
      type: String,
      default: "Air icon",
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.st0 {
  opacity: 0.3;
}
.st1 {
  fill: none;
}
.st2 {
  fill: none;
  stroke: #fff;
  stroke-width: 1.5;
}
</style>