<script>
import PlusIcon from "vue-material-design-icons/Plus.vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import PlusBoxOutlineIcon from "vue-material-design-icons/Plus.vue";
import CloseBoxOutlineIcon from "vue-material-design-icons/Close.vue";
import DotsHorizontalIcon from "vue-material-design-icons/DotsHorizontalCircle.vue";
import DotsVerticalIcon from "vue-material-design-icons/DotsVerticalCircle.vue";
import RecordCircleIcon from "vue-material-design-icons/RecordCircle.vue";
import FullscreenIcon from "vue-material-design-icons/Fullscreen.vue";
import EyeCircleIcon from "vue-material-design-icons/EyeCircleOutline.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import CheckboxBlankCircleOutlineIcon from "vue-material-design-icons/CheckboxBlankCircleOutline.vue";
import CircleOutlineIcon from "vue-material-design-icons/CircleOutline.vue";
import CircleSlice8 from "vue-material-design-icons/CircleSlice8.vue";
import StopIcon from "vue-material-design-icons/Stop.vue";


export default {
  install(Vue) {
    Vue.component("plus-icon", PlusIcon);
    Vue.component("magnify-icon", MagnifyIcon);
    Vue.component("plus-box-outline-icon", PlusBoxOutlineIcon);
    Vue.component("close-box-outline-icon", CloseBoxOutlineIcon);
    Vue.component("dots-horizontal-icon", DotsHorizontalIcon);
    Vue.component("dots-vertical-icon", DotsVerticalIcon);
    Vue.component("record-circle-icon", RecordCircleIcon);
    Vue.component("fullscreen-icon", FullscreenIcon);
    Vue.component("eyecircle-icon", EyeCircleIcon);
    Vue.component("menu-icon", MenuIcon);
    Vue.component("pencil-icon", PencilIcon);
    Vue.component("chevron-left", ChevronLeft);
    Vue.component("chevron-right", ChevronRight);
    Vue.component("check-icon", CheckIcon);
    Vue.component("circle-icon", CircleOutlineIcon);
    Vue.component("circle-slice8-icon", CircleSlice8);
    Vue.component("stop-icon", StopIcon);
     Vue.component(
      "checkbox-blank-circle-outline-icon",
      CheckboxBlankCircleOutlineIcon
    );
  },
};
</script>