<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-narrative-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="material-design-icon__svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 32 32"
    >
      <g xmlns="http://www.w3.org/2000/svg" id="Knowledge">
        <g>
          <circle class="cls-1" cx="16" cy="16" r="16" />
          <g>
            <polygon
              class="cls-2"
              points="8.85 11.39 6.12 20.89 9.82 20.89 11.34 11.39 8.85 11.39"
            />
            <polygon
              class="cls-2"
              points="15.31 11.39 12.59 20.89 16.28 20.89 17.8 11.39 15.31 11.39"
            />
            <polygon
              class="cls-2"
              points="21.78 11.39 19.05 20.89 22.74 20.89 24.27 11.39 21.78 11.39"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfNarrativeIcon",
  props: {
    title: {
      type: String,
      default: "Narrative icon",
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #46c8f5;
}

.cls-1,
.cls-2 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #fff;
}
</style>