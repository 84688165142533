export default async function splitFile(
    file,
    chunkSize,
    callback,
) {
    const fileSize = file.size;
    let offset = 0, result;

    const readChunk = async () => {
        const sequenceNumber = (offset / chunkSize);

        const blob = file.slice(offset, chunkSize + offset);
        offset += blob.size;

        result = await callback(blob, sequenceNumber);
        if (offset >= fileSize) {
            // Done reading file.
            return result;
        }

        // Off to the next chunk.
        return readChunk();
    };

    return new Promise((resolve) => {
        readChunk().then(() => {
            resolve(result);
        });
    });
}