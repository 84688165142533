<template>
  <div id="app">
    <router-view> </router-view> 
    <!--
      Cannot use :key="$route.fullPath" because, for the same cId, pId URL values, open contexts can differ.
    -->
  </div>
</template>

<script>

export default {
  name: "app",
};
</script>
<style>
#app,
#demo {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #4e4e4e;
}

#app > .container-fluid,
#demo > .container-fluid {
  padding: 0;
}
#demo > .container-fluid {
  max-height: 20vh;
}
html {
  height: 100%;
  overflow: hidden;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #e9e9ee;
}
body,
a,
.navbar-button,
.navbar-button:hover,
.navbar-button:active,
.navbar-button:focus {
  color: rgba(0, 0, 0, 0.78);
}

.form-input {
  border: none;
  border-bottom: 1px solid #e2e2e2;
}

.form-input:focus {
  background-color: #ffffff;
  outline: none;
  border: none;
  border-bottom: 2px solid #fefe00;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: #bae368 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tab-active,
.router-link-active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
  background-color: transparent;
  border: none;
}

.tab-active:hover {
  background-color: transparent;
  color: #fff;
}

/* .material-design-icon {
  color: #949494;
} */

.footer-col .material-design-icon {
  padding: 1vh;
}

.material-design-icon:hover {
  /* background-color: var(--primary); */
  cursor: pointer;
  color: #fff;
}

.iconBoundRight {
  border-right: 1px solid #d6d6d6;
}

#app .card {
  border: 0px !important;
  background-color: transparent;
}
</style>