<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-air-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 64 64"
    >
      <g
        id="Group_14854"
        data-name="Group 14854"
        transform="translate(-2436 -120)"
      >
        <path
          id="Rectangle_11860"
          data-name="Rectangle 11860"
          d="M10,0H54A10,10,0,0,1,64,10V64a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z"
          transform="translate(2436 120)"
          fill="#e9e9ee"
        />
        <path
          id="Path_17038"
          data-name="Path 17038"
          d="M12,12.818a4.463,4.463,0,0,0,3.283-1.353,4.479,4.479,0,0,0,1.35-3.286A4.614,4.614,0,0,0,12,3.545,4.464,4.464,0,0,0,8.714,4.9a4.479,4.479,0,0,0-1.35,3.286A4.615,4.615,0,0,0,12,12.818m-.009-1.582a2.933,2.933,0,0,1-2.162-.893,2.963,2.963,0,0,1-.886-2.168,2.93,2.93,0,0,1,.893-2.162,2.963,2.963,0,0,1,2.168-.886,2.931,2.931,0,0,1,2.162.893,2.961,2.961,0,0,1,.886,2.168,2.93,2.93,0,0,1-.892,2.162,2.963,2.963,0,0,1-2.168.886M12,16.364A12.224,12.224,0,0,1,4.8,14.1,13.794,13.794,0,0,1,0,8.182,13.794,13.794,0,0,1,4.8,2.264,12.224,12.224,0,0,1,12,0a12.221,12.221,0,0,1,7.2,2.264A13.79,13.79,0,0,1,24,8.182,13.79,13.79,0,0,1,19.2,14.1,12.221,12.221,0,0,1,12,16.364m0-1.636a10.975,10.975,0,0,0,6.073-1.786,11.6,11.6,0,0,0,4.214-4.759,11.6,11.6,0,0,0-4.209-4.759A10.951,10.951,0,0,0,12,1.636,10.978,10.978,0,0,0,5.932,3.423,11.788,11.788,0,0,0,1.691,8.182a11.8,11.8,0,0,0,4.237,4.759A10.954,10.954,0,0,0,12,14.727"
          transform="translate(2456 143.818)"
          fill="#333"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfDetailPanelIcon",
  props: {
    title: {
      type: String,
      default: "Detail Panel icon",
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.st0 {
  opacity: 0.3;
}
.st1 {
  fill: none;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
}
</style>