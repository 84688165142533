<script >
import PlusIcon from "vue-material-design-icons/Plus.vue";
import MinusIcon from "vue-material-design-icons/Minus.vue";

export default {
  name: "TreeNodeToggle",
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: {
    node: { type: Object, default: null },
    handleClick: {type: Function, default: null}
  },
  computed: {
    showingChildren(){
      return this.node.showChildren === true;
    },
    someChildrenVisible() {
      return this.node.children.some((child) => child.show);
    },
  },
};
</script>

<template slot="node-toggle" slot-scope="{ node }">
  <span
    class="teatree-node-item-icon"
    v-if="node.children && node.children.length && someChildrenVisible && 
    (!node.categorySelectable || (node.categorySelectable && node.categorySelected))"
    @click="() => handleClick(node)"
  >
    <minus-icon v-if="showingChildren===true" :size="18" />
    <plus-icon v-else :size="18" />
  </span>
</template>

<style>
.teatree-node-item-icon {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: #cbd5e0;
}
</style>