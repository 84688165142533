<template functional>
  <span class="pf-filter-icon-placeholder" role="img" v-on="listeners">
    <svg :width="props.size" :height="props.size" viewBox="0 0 25.959 12.172" />
  </span>
</template>

<script>
export default {
  name: "PfFilterIconPlaceholder",
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style scoped>
</style>