export const VEHICLE_TYPE = {
  CAR: "CAR",
  TRUCK: "TRUCK",
  TRAM: "TRAM",
  EBIKE: "EBIKE"
};

export const ZONE_TYPE = {
  ASC: "ASC",
  DP: "DP"
}

export const ASSET_CATEGORY = {
  ZONE: "zone",
  VEHICLE: "vehicle",
  KNOWLEDGE: "knowledge",
  MEDIA: "media"
};

export const ZONE_TYPE_MAP = {
  "BIKE_POWER_CUT": "ASC",
  "PEAK": "DP",
  "PANDEMIC": "DP",
  "NO_ACCESS": "ASC",
  "NO_ACCESS_9_17": "DP",
  "NO_HEAVY_LOAD": "ASC"
}

export const MODE_COLOR = {
  "E55": "red",
  "E10": "green",
  "AMS5": "cyan"
}
