<template>
  <!-- object value -->
  <model-select
    :options="projects"
    v-model="item"
    placeholder="Search"
    @input="handleOnInput"
    v-if="showList"
  >
  </model-select>
</template>

<script>
import { ModelSelect } from "vue-search-select";
import { EventBus } from "@/components/EventBus.js";
import { api } from "@/api/api.js";
import Vue from "vue";

export default {
  data() {
    return {
      item: {
        value: "",
        text: "",
      },
      showList: false,
    };
  },
  computed: {
    projects() {
      return this.$store.getters.projects;
    },
  },
  methods: {
    reset() {
      this.item = {};
    },
    selectFromParentComponent1() {
      // select option from parent component
      this.item = this.projects[0];
    },
    handleOnInput($event) {
      this.item = $event;
      const state = this.$store.state;
      this.$store.commit("setActiveProject", this.item);
      state.openContexts.splice(0, state.openContexts.length);
      state.contexts.splice(0, state.contexts.length);
      EventBus.$emit("projectSelected", this.item.value);
      this.showList = false;
    },
    initProjects(pId) {
      const store = this.$store;
      if (!store.getters.user) {
        return;
      }
      api.getProjects().then((res) => {
        const projects = res.data._embedded["be:organization_project"];
        projects.forEach((p) => {
          const item = {
            value: p.id,
            text: p.name,
            accesses: p.accesses,
          };
          store.state.projects.push(item);
        });
        store.state.projects.push(...projects);
        if (pId) {
          const selected = store.state.projects.filter((p) => p.value === pId);
          if (!selected.length) {
            console.error("Invalid project URL");
          } else {
            Vue.set(store.state, "activeProject", selected[0]);
          }
        }
      });
    },
  },
  components: {
    ModelSelect,
  },
  created() {
    const store = this.$store;
    EventBus.$on("projectClosed", () => {
      Vue.set(store.state, "activeProject", null);
      store.state.openContexts.splice(0, store.state.openContexts.length);
      store.state.contexts.splice(0, store.state.openContexts.length);
    });
    EventBus.$on("toggleProjectSelector", () => {
      this.showList = !this.showList;
    });
    const pId = this.$route.params.pId;
    this.initProjects(pId);
  },
  beforeDestroy() {
    EventBus.$off("projectClosed");
  },
};
</script>

<style>
.search.selection.dropdown {
  position: absolute;
  top: 6vh;
  left: 0;
  height: 5vh;
  z-index: 10;
  width: 12vw;
  border-radius: 0;
  border: none;
}
.ui.fluid.dropdown > .dropdown.icon,
.ui.search.dropdown > .search,
.ui.search.dropdown > .text {
  padding: 0;
  font-size: var(--fsize-filterpanel);
}
.ui.fluid.dropdown > .dropdown.icon {
  display: none;
}
.ui.selection.dropdown .menu {
  box-shadow: none;
  display: block !important;
  border-radius: 0;
  border: none;
}

.ui.selection.dropdown .menu .item {
  padding: 0.5vw !important;
  font-size: var(--fsize-filterpanel);
}
</style>
