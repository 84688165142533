<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.size"
      :height="props.size" viewBox="0 0 64 64">
  <g id="Group_14834" data-name="Group 14834" transform="translate(-1186 -120)">
    <path id="Rectangle_11854" data-name="Rectangle 11854" d="M10,0H54A10,10,0,0,1,64,10V64a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" transform="translate(1186 120)" fill="#333"/>
    <path id="Path_17035" data-name="Path 17035" d="M0,24H12V21H0ZM0,13.5H12v-3H0ZM0,3H12V0H0Z" transform="translate(1212 140.001)" fill="#fff"/>
  </g>
</svg>
  </span>
</template>

<script>
export default {
  name: "PfFilterOpenIcon",
  props: {
    title: {
      type: String,
      default: "Filter Open Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>