<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 32 32"
    >
      <g id="Knowledge">
        <g>
          <circle class="cls-1" cx="16" cy="16" r="16" />
          <g>
            <polygon
              class="cls-2"
              points="11.33 21.39 14.5 21.39 14.5 19.97 13 19.97 13 12.31 14.5 12.31 14.5 10.89 11.33 10.89 11.33 21.39"
            />
            <polygon
              class="cls-2"
              points="17.5 10.89 17.5 12.31 19 12.31 19 19.97 17.5 19.97 17.5 21.39 20.67 21.39 20.67 10.89 17.5 10.89"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfResolutionsFilterIcon",
  props: {
    title: {
      type: String,
      default: "Resolutions Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #99ca3c;
}

.cls-1,
.cls-2 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #fff;
}
</style>