<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      viewBox="0 0 32 32"
      :width="props.size"
      :height="props.size"
    >
      <g id="Journeys">
        <g>
          <path
            class="cls-1"
            d="m16,1c8.27,0,15,6.73,15,15s-6.73,15-15,15S1,24.27,1,16,7.73,1,16,1m0-1C7.16,0,0,7.16,0,16s7.16,16,16,16,16-7.16,16-16S24.84,0,16,0h0Z"
          />
          <g>
            <polygon
              class="cls-1"
              points="16.91 12.82 16.91 9.81 15.09 9.81 15.09 12.82 11.5 12.82 11.5 14.68 15.09 14.68 15.09 17.69 16.91 17.69 16.91 14.68 20.5 14.68 20.5 12.82 16.91 12.82"
            />
            <rect class="cls-1" x="11.5" y="20.6" width="9" height="1.59" />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfMediaFilterIcon",
  props: {
    title: {
      type: String,
      default: "Media Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
  stroke-width: 0px;
}
</style>