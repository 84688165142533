<template>
  <div class="main">
    <div
      class="route-map-container"
      @keydown.esc="closeContextMenu"
      tabindex="0"
    >
      <b-toast id="denied" title="No write permissions" :solid="true">
        Please request admin team to allow access.
      </b-toast>
      <b-toast id="noread" title="No read permissions" :solid="true">
        Please request admin team to allow access.
      </b-toast>
      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :zoom="zoom"
        :center="getCenter"
        :minZoom="10"
        :interactive="interactive"
        :maxZoom="15.25"
        @load="onLoad"
        @dblclick="onMapClick($event)"
        @contextmenu="onRightClick($event)"
      >
        <template v-for="m in filterItems">
          <component
            :is="m.component"
            :cId="cId"
            :currentCenter="currentCenter"
            :currentZoom="currentZoom"
            :key="m.id"
            :filterItem="m"
          />
        </template>
      </MglMap>
    </div>
  </div>
</template>

<script>
import { MglMap } from "vue-mapbox";
import emptyStyle from "@/assets/map-style/empty.json";
import { EventBus } from "@/components/EventBus.js";
import KnowledgeAssets from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/KnowledgeAssets.vue";

export default {
  name: "ContextMap",
  props: {
    id: {
      type: String,
      default: "routeMap",
    },
    zoom: {
      type: Number,
      default: 14,
    },
    center: {
      type: [Object, Array],
      default: emptyStyle.center,
    },
    interactive: {
      type: Boolean,
      default: true,
    },
    cId: {
      type: String,
      default: null,
    },
    filterItems: {
      type: [Object, Array],
      default: null,
    },
  },
  components: {
    MglMap,
    KnowledgeAssets,
  },
  computed: {
    getCenter() {
      const ent = this.$store.state.map.entities[0];
      if (ent) {
        return ent.coordinates;
      } else {
        return this.center;
      }
    },
  },
  created() {
    console.log("Context Map created");
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiYmlsaHVsayIsImEiOiJjazJsbjE1aHcwODQzM2hwZWx6emtkNTJwIn0.Egrgi-9x5fs6IHfYHH_erg",
      mapStyle: this.interactive
        ? "mapbox://styles/productfoundry/ckyjwfr6w0sox15qllreek5iz"
        : emptyStyle,
      ref: {},
      options: [{ name: "narrative" }],
      metersPerPixel: 100,
      currentZoom: this.zoom,
      currentCenter: this.center,
    };
  },
  methods: {
    onLoad(e) {
      this.map = e.map;
      this.map.doubleClickZoom.disable();
      this.map.on("zoomend", (e) => {
        this.currentZoom = e.target.getZoom();
        this.currentCenter = [
          e.target.getCenter().lng,
          e.target.getCenter().lat,
        ];
        this.setMetersPerPixel();
      });
      EventBus.$on("viewChange", () => {
        window.setTimeout(() => {
          this.map.resize();
        }, 1000);
      });
      this.setMetersPerPixel();
      this.mapLoaded = true;
      const ap = this.$store.getters.activeProject;
      const allowed = ap.accesses.indexOf("PROJECT_READ");
      if (allowed < 0) {
        this.$bvToast.show("noread");
        return;
      }
      EventBus.$on("gotoOnMap", this.gotoOnMap);
    },
    setMetersPerPixel() {
      this.metersPerPixel =
        (40075.016686 *
          Math.abs(Math.cos((this.map.getCenter().lat * Math.PI) / 180))) /
        Math.pow(2, this.map.getZoom());
    },
    onMapClick($e) {
      if (!this.interactive) return;
      const ap = this.$store.getters.activeProject;
      const allowed = ap.accesses.indexOf("PROJECT_CREATE_ENTITY");
      if (allowed < 0) {
        this.$bvToast.show("denied");
        return;
      }
      EventBus.$emit("mapDblClick", $e);
    },
    onRightClick($e) {
      if (!this.interactive) return;
      const ap = this.$store.getters.activeProject;
      const allowed = ap.accesses.indexOf("PROJECT_CREATE_ENTITY");
      if (allowed < 0) {
        this.$bvToast.show("denied");
        return;
      }
      EventBus.$emit("mapRightClick", $e);
    },
    gotoOnMap(selected) {
      // selected could be an entity or feature
      let pt = selected.coordinates || selected.geometry.coordinates;
      if (Array.isArray(pt[0])) {
        pt = pt[0];
      }
      this.map.flyTo({ center: pt });
    },
    closeContextMenu() {
      EventBus.$emit("mapEscKey");
    },
  },
  destroyed() {
    EventBus.$off("viewChange");
    EventBus.$off("gotoOnMap");
  },
};
</script>

<style>
.route-map-container {
  height: 100%;
}

.main {
  flex: 1;
  height: var(--content-ht);
  width: 100%;
}

.action-icon:hover .map-label,
.blink-2.map-label {
  display: block;
}

.router-link-active .selected {
  display: block;
}

.map-label,
.ebike-label,
.dp-label {
  display: block;
  width: max-content;
  font-size: var(--fsize-map);
  background: #fff;
  color: #000;
  padding: 0.1vw 0.3vw;
  line-height: 1rem;
  margin-top: -1.3rem;
  text-align: center;
}

.map-label {
  position: absolute;
  display: none;
}

.map-label.place {
  display: block;
}

.ebike-label {
  color: white;
}

.map-label svg {
  fill: #ff3333;
}

.ebike-label svg {
  fill: white;
}

.dp-label {
  color: violet;
}

.dp-label svg {
  fill: violet;
}

.map-label .material-design-icon {
  vertical-align: bottom;
  padding-right: 0.4rem;
}

.ebike-label > span {
  vertical-align: bottom;
  padding: 0 0.4rem 0 0.5rem;
}

.map-label .material-design-icon:hover {
  background: transparent;
}

.mapboxgl-canvas-container {
  height: var(--map-ht);
}
/* .mapboxgl-canvas-container.mapboxgl-interactive canvas {
  width: 100% !important;
  height: 100% !important;
} */
</style>
