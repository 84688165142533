<script>
import Vue from "vue";
import PfFilterIcon from "./PfFilterIcon.vue";
import PfFilterOpenIcon from "./PfFilterOpenIcon.vue";
import PfFilterIconPlaceholder from "./PfFilterIconPlaceholder.vue";
import PfResolutionsFilterIcon from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/PfResolutionsFilterIcon.vue";
import PfNarrativesFilterIcon from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/PfNarrativesFilterIcon.vue";
import PfKnowledgeFilterIcon from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/PfKnowledgeFilterIcon.vue";
import PfForcesFilterIcon from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/PfForcesFilterIcon.vue";
import PfMediaFilterIcon from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/media/PfMediaFilterIcon.vue";

Vue.component("pf-filter-icon", PfFilterIcon);
Vue.component("pf-filter-open-icon", PfFilterOpenIcon);
Vue.component("pf-resolutions-filter-icon", PfResolutionsFilterIcon);
Vue.component("pf-narratives-filter-icon", PfNarrativesFilterIcon);
Vue.component("pf-filter-icon-placeholder", PfFilterIconPlaceholder);
Vue.component("pf-forces-filter-icon", PfForcesFilterIcon);
Vue.component("pf-media-filter-icon", PfMediaFilterIcon);
Vue.component("pf-knowledge-filter-icon", PfKnowledgeFilterIcon);

export default {
  name: "FilterTreeIcon",
  computed: {},
  props: {
    icon: {
      type: String,
    },
    className: {
      type: String,
    },
    size: {
      type: Number,
      default: 14,
    },
    fillColor: {
      type: String,
      default: "#ff3333"
    }
  },
};
</script>

<template >
  <span :class="this.className">
    <pf-asc-filter-icon
      v-if="this.icon == 'pf-asc-filter-icon'"
      className="filter-icon"
      :size="size"
      :fillColor="fillColor"
    />
    <pf-vehicles-filter-icon
      v-if="this.icon == 'pf-vehicles-filter-icon'"
      className="filter-icon"
      :size="size"
    />
    <pf-narratives-filter-icon
      v-if="this.icon == 'pf-narratives-filter-icon'"
      className="filter-icon"
      :size="size"
    />
    <pf-forces-filter-icon
      v-if="this.icon == 'pf-forces-filter-icon'"
      className="filter-icon" :size="size" 
    />
    <pf-resolutions-filter-icon
      v-if="this.icon == 'pf-resolutions-filter-icon'"
      className="filter-icon" :size="size" 
    />
     <pf-media-filter-icon
      v-else-if="this.icon == 'pf-media-filter-icon'"
      className="filter-icon"
      :size="size"
    />
    <pf-knowledge-filter-icon
      v-else-if="this.icon == 'pf-knowledge-filter-icon'"
      className="filter-icon"
      :size="size"
    />
  <pf-adaptivespeed-filter-icon v-else-if="this.icon == 'pf-adaptivespeed-filter-icon'" className="filter-icon" :size="size"  />
  <pf-car-filter-icon v-else-if="this.icon == 'pf-car-filter-icon'" className="filter-icon" :size="size"  />
  <pf-ebike-filter-icon v-else-if="this.icon == 'pf-ebike-filter-icon'" className="filter-icon" :size="size"  />
  <pf-truck-filter-icon v-else-if="this.icon == 'pf-truck-filter-icon'" className="filter-icon" :size="size"  />
  <pf-tram-filter-icon v-else-if="this.icon == 'pf-tram-filter-icon'" className="filter-icon" :size="size"  />
  <pf-filter-icon-placeholder v-else :role="this.icon"/>
  </span>
</template>

<style>
/* .filter-icon {
  opacity: .3;
} */
.teatree-node-item-selected .filter-icon {
  opacity: 1;
}
.teatree-node .iconRight {
  margin-left: auto;
  margin-right: 1vw;
}
</style>