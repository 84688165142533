import { render, staticRenderFns } from "./PfAddProjectIcon.vue?vue&type=template&id=948b81b4&scoped=true&functional=true&"
import script from "./PfAddProjectIcon.vue?vue&type=script&lang=js&"
export * from "./PfAddProjectIcon.vue?vue&type=script&lang=js&"
import style0 from "./PfAddProjectIcon.vue?vue&type=style&index=0&id=948b81b4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "948b81b4",
  null
  
)

export default component.exports