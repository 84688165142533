<template>
  <model-select
    :options="contexts"
    v-model="item"
    placeholder="Search"
    @input="handleOnInput"
    :class="open ? 'show' : 'hide'"
  >
  </model-select>
</template>

<script>
import { ModelSelect } from "vue-search-select";
import { EventBus } from "@/components/EventBus.js";
import { api } from "@/api/api.js";

export default {
  data() {
    return {
      item: {
        value: "",
        text: "",
      },
      open: false
    };
  },
  methods: {
    reset() {
      this.item = {};
    },
    selectFromParentComponent1() {
      this.item = this.contexts[0];
    },
    handleOnInput($event) {
      this.item = $event;
      this.setActiveContext(this.item);
      this.open = false;
    },
    setActiveContext(active) {
      if (active) {
        // can be null when all contexts are closed
        this.$store.commit("addToOpenContexts", active);
        this.$store.commit("setActiveContext", active.value);
      } else {
        this.$store.commit("setActiveContext", null);
      }
      EventBus.$emit("activeContextChanged", active);
      this.$store.commit("showOnlyMap");
    },
    initContextLists(active) {
      const store = this.$store;
      const pId = store.state.activeProject.value;
      api.getContexts(pId).then((res) => {
        const contexts = res.data._embedded["be:project_context"].filter(
          (c) => c.name.indexOf(" pw") === -1
        );
        const photowalks = res.data._embedded["be:project_context"].filter(
          (c) => c.name.indexOf(" pw") > -1
        );
        const pwContexts = store.state.pwContexts;
        pwContexts.splice(
          0,
          pwContexts.length,
          ...photowalks.map((pw) => {
            return { text: pw.name, value: pw.id };
          })
        );
        store.state.contexts.splice(
          0,
          store.state.contexts.length,
          ...contexts.map((c) => {
            return { text: c.name, value: c.id };
          })
        );
        if (active) {
          const selected = store.state.contexts.filter(
            (c) => c.value === active
          )[0];
          if (selected) {
            store.state.openContexts.splice(
              0,
              store.state.openContexts.length,
              selected
            );
            store.state.activeContext = selected.value;
          }
        } else {
          store.state.openContexts.splice(0, store.state.openContexts.length);
        }
      });
    },
    clearContextLists() {
      const store = this.$store;
      const pwContexts = store.state.pwContexts;
      pwContexts.splice(0, pwContexts.length);
      store.state.contexts.splice(0, store.state.contexts.length);
      store.state.openContexts.splice(0, store.state.openContexts.length);
    },
    projectSelectedHandler() {
      this.initContextLists(null);
      EventBus.$emit("activeContextChanged", null);
    },
    projectClosedHandler() {
      this.clearContextLists();
      EventBus.$emit("activeContextChanged", null);
    },
  },
  components: {
    ModelSelect,
  },
  props: {
    contexts: {
      type: [Object, Array],
      default: null,
    },
  },
  created() {
    const cId = this.$route.params.cId;
    this.initContextLists(cId);
    EventBus.$on("requestACchange", (active) => {
      this.setActiveContext(active);
    });

    EventBus.$on("projectSelected", this.projectSelectedHandler);
    EventBus.$on("projectClosed", this.projectClosedHandler);
    EventBus.$on("toggleContextSelector", () => {
      this.open = !this.open;
    });
  },
  destroyed() {
    EventBus.$off("requestACchange");
    EventBus.$off("projectSelected", this.projectSelectedHandler);
    EventBus.$off("projectClosed", this.projectClosedHandler);
    EventBus.$off("toggleContextSelector");
  },
};
</script>

<style>
.search.selection.dropdown {
  position: absolute;
  top: 6vh;
  left: 0;
  height: 5vh;
  z-index: 10;
  width: 12vw;
  border-radius: 0;
  border: none;
}
.search.search.selection.dropdown.hide {
  display: none;
}
.ui.fluid.dropdown > .dropdown.icon,
.ui.search.dropdown > .search,
.ui.search.dropdown > .text {
  padding: 0;
  font-size: var(--fsize-filterpanel);
}
.ui.fluid.dropdown > .dropdown.icon {
  display: none;
}
.ui.selection.dropdown .menu {
  box-shadow: none;
  display: block !important;
  border-radius: 0;
  border: none;
}

.ui.selection.dropdown .menu .item {
  padding: 0.5vw !important;
  font-size: var(--fsize-filterpanel);
}
</style>
