import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import KBaseDemo from '@/components/demo/Demo'
// import { mutations, store } from '@/store.js'

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    props: true
  },
  {
    path: '/demo',
    name: 'demo',
    component: KBaseDemo,
    props: true,
    children: [
      {
        path: ':pId',
        name: 'project',
      },
      {
        path: ':pId/:cId',
        name: 'surface',
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router
