<script>
export default {
  name: "tm-footer",
  data: function() {
    return {
      copyright: process.env.VUE_APP_SHOW_COPYRIGHT === 'true'
    }
  }
};
</script>

<template>
  <div class="footer">
      <span
        class="copyright"
      >© Townmaking Institute</span>
  </div>
</template>

<style scoped>
.copyright {
  color: #b1b1b1;
  font-size: var(--fsize-footer);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.footer {
  position: absolute;
  bottom: 0;
}
.avatar-col {
  padding: 7px;
  margin-right: 10px;
}
.vue-avatar--wrapper {
  height: 5vh !important;
  width: 5vh !important;
  background-position: bottom !important;
  background-size: contain !important;
}
.footer-col {
  background: #ffffff;
  padding-left: 0px;
  height: 6vh;
}
</style>