<template>
  <div id="demo" :class="splitView ? 'split-view' : 'full-view'">
    <b-toast
      id="nopwsurface"
      title="No related photowalk surface on Clay"
      :solid="true"
    >
      Please create a photowalk surface in Clay.
    </b-toast>
    <b-toast id="readerror" title="No read permissions" :solid="true">
      Please request admin team to allow access.
    </b-toast>
    <TopNavBar :pId="pId"></TopNavBar>
    <div id="stage" :class="writerRole ? 'write-enabled' : 'read-only'">
      <ProjectArea :pId="pId"></ProjectArea>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from "@/components/demo/navbar/TopNavBar";
import Footer from "@/components/demo/Footer";
import ProjectArea from "@/components/demo/projectarea/ProjectArea.vue";
import { EventBus } from "@/components/EventBus.js";

window.data = "";

export default {
  name: "kBase-demo",
  components: {
    ProjectArea,
    TopNavBar,
    Footer,
  },
  computed: {
    splitView() {
      const v = this.$store.getters.view;
      return v === "detailPanelShown";
    },
    writerRole() {
      const p = this.$store.getters.activeProject;
      if (!p) return false;
      const i = p.accesses.indexOf("PROJECT_CREATE_ENTITY");
      return i > -1;
    },
    cId() {
      return this.$route.params.cId;
    },
    pId() {
      return this.$route.params.pId;
    },
  },
  methods: {
    activeContextChangedHandler(con) {
      const activeProject = this.$store.getters.activeProject;
      if (con && activeProject) {
        this.$router.push("/demo/" + activeProject.value + "/" + con.value);
        EventBus.$emit("viewChange");
      } else if (!activeProject) {
        this.$router.push("/demo");
      } else {
        this.$router.push("/demo/" + activeProject.value);
      }
    },
  },
  created: function () {
    EventBus.$on("projectSelected", (pId) => {
      this.$store.commit("setActiveContext", null);
      this.$router.push("/demo/" + pId);
    });
     EventBus.$on("activeContextChanged", this.activeContextChangedHandler);
  },
  destroyed() {
    EventBus.$off("projectSelected");
    EventBus.$off("activeContextChanged");
  },
};
</script>
<style>
#demo {
  width: 100vw;
}
#stage {
  display: flex;
  margin-top: var(--stage-margintop);
  height: calc(100vh - var(--topnavbar-ht) - var(--stage-margintop));
}
/* .right {
  width: 50vw;
} */
</style>