import { render, staticRenderFns } from "./PfDetailPanelOpenIcon.vue?vue&type=template&id=167f5770&scoped=true&functional=true&"
import script from "./PfDetailPanelOpenIcon.vue?vue&type=script&lang=js&"
export * from "./PfDetailPanelOpenIcon.vue?vue&type=script&lang=js&"
import style0 from "./PfDetailPanelOpenIcon.vue?vue&type=style&index=0&id=167f5770&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "167f5770",
  null
  
)

export default component.exports